import React, { useState } from "react";
import './month.css';

const MultiPicker = () => {
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());

    const minDate = new Date(2023, 2); 

    const months = [
        { shortName: "Jan", value: "01" },
        { shortName: "Feb", value: "02" },
        { shortName: "Mar", value: "03" },
        { shortName: "Apr", value: "04" },
        { shortName: "May", value: "05" },
        { shortName: "Jun", value: "06" },
        { shortName: "Jul", value: "07" },
        { shortName: "Aug", value: "08" },
        { shortName: "Sep", value: "09" },
        { shortName: "Oct", value: "10" },
        { shortName: "Nov", value: "11" },
        { shortName: "Dec", value: "12" },
    ];

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const handleMonthSelect = (monthValue) => {
        const selectedMonth = `${monthValue}/${year}`;
        setSelectedMonths((prev) => {
            if (prev.includes(selectedMonth)) {
                return prev.filter((m) => m !== selectedMonth);
            } else {
                return [...prev, selectedMonth];
            }
        });
    };

    const handleYearChange = (offset) => {
        setYear(year + offset);
    };

    const isMonthBeforeMinDate = (monthValue) => {
        const selectedDate = new Date(year, parseInt(monthValue) - 1);
        return selectedDate < minDate;
    };


    return (
        <div style={{ marginTop: '200px' }}>
            <input
                type="text"
                value={selectedMonths.join(", ")}
                onClick={togglePopover}
                readOnly
                placeholder="Select Months/Year"
                style={{
                    width: '100%',
                    height: "40px",
                    boxSizing: 'border-box',
                    border:'1px solid rgba(0,0,0,0.4)',
                    display: 'block',
                }}
                onFocus={(e) => e.target.style.border = '2px solid #E22B16'}
            />

            {isPopoverOpen && (
                <div className="popover">
                    <div className="popover-header">
                        <button onClick={() => handleYearChange(-1)}>{"<"}</button>
                        <span>{year}</span>
                        <button onClick={() => handleYearChange(1)}>{">"}</button>
                    </div>
                    <div className="popover-body">
                        {months.map((month) => (
                            <button
                                key={month.value}
                                onClick={() => !isMonthBeforeMinDate(month.value) && handleMonthSelect(month.value)}
                                className={`month-btn ${selectedMonths.includes(`${month.value}/${year}`) ? "selected" : ""}`}
                                disabled={isMonthBeforeMinDate(month.value)}
                            >
                                {month.shortName}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiPicker;
