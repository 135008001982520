import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, InputAdornment, MenuItem, Paper, Select, Switch, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Table, FormControl, InputLabel, Modal } from '@mui/material';
import { ChangeRole, DeleteIcon, DisableUser, EditIcon, EditIconn, EditmenuIcon, EnableUser, FileImage, NoDataImage, RoundedTickMark, SearchNormal, TrashUser, VisibilityEye, fb1, instagramI } from '../../assets';
import Breadcrumb from '../../Atoms/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import NoData from '../../error/NoData';
import IOSSwitch from '../../Atoms/buttons/IOSSwitch';
import { Close, KeyboardArrowDownOutlined, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUpOutlined, VisibilityOff } from '@mui/icons-material';
import ReusableTable from '../../Molecules/table-management/ReusableTable';
import ActionButton from '../../Molecules/table-management/ActionButton';
import VisibilityButton from '../../Atoms/Visibility/VisibilityButton';
import AddingModal from './AddingModal';
import UserManagementTable from '../../Molecules/table-management/UserManagementTable';
import UserActions from './UserActions';
import ZupotsuButton from '../../Atoms/zupotsu-button/zupotsu-button';
import ZupotsuTextfield from '../Settings/ZupotsuTextfield';
import ZupotsuDropdown from '../../Atoms/zupotsu-dropdown/zupotsu-dropdown';
import Apis from '../../services/apis';


type FormAttribute = {
  attribute_priority: number;
};

const ZupotsuUnregistered: React.FC = () => {
  const [formState, setFormState] = useState<{ [key: string]: string }>({});
  const isSearched = useRef<boolean>(false);
  const headers = ['Name', 'Email', 'Mobile No', 'Organisation'
    //  "Role", "Asset Listed", "status", 'Action'
  ];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState<any>({});
  const [socialLinks, setSocialLinks] = useState([]);
  const [type, setType] = useState("Buyer")
  const [popup, setPopup] = useState<any>(false)
  const [editBuyer, setEditBuyer] = useState<any>({})
  const [popuptype, setPopuptype] = useState<any>("")
  const [reason, setReason] = useState('')
  const [currentUser, setCurrentUser] = useState<any>("Buyer")
  const [newUser, setnewUser] = useState<any>("")
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [callApi, setCallApi] = useState(false)
  const apis = new Apis();
  const handleInputChange = (e: any, fieldName: any) => {
    setFormData((prevData: any) => {
      const newFormData = { ...prevData };
      newFormData[fieldName] = e.target.value;
      return newFormData;

    });
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditBuyer((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const [socialMediaItems, setSocialMediaItems] = useState([
    {
      name: 'Facebook',
      value: 'facebook',
      img: fb1,
      showDefault: true,
      placeHolder: 'Facebook',
      isError: false,
      showInputView: true,
      errorMessage: 'Enter Valid Facebook Url',
    },
    {
      name: 'Instagram',
      value: 'instagram',
      img: instagramI,
      showDefault: true,
      placeHolder: 'Instagram',
      isError: false,
      showInputView: true,
      errorMessage: 'Enter Valid Instagram Url',
    },])



  const onChangeSocial = useCallback((param: any, isValidationError: any) => {
    setSocialLinks(param);
  }, []);
  // const handleSearchValue = async (value: string) => {
  //   isSearched.current = true;
  //   // setSearchValue(value);
  // };
  const [visibility, setVisibility] = useState(false);

  const onVisibility = () => {
    setVisibility(!visibility)
  }


  const menuOptions = [
    {
      menuname: 'Change Role',
      type: 'changerole',
      menuIcon: ChangeRole
    },

    {
      menuname: editBuyer?.is_active ? 'Disable Seller' : "Activate Seller",
      type: 'disableuser',
      menuIcon: editBuyer?.is_active ? DisableUser : EnableUser
    },

    {
      menuname: 'Edit',
      type: 'edit',
      menuIcon: EditmenuIcon
    },
    {
      menuname: 'Delete',
      type: 'deleteuser',
      menuIcon: TrashUser
    },
  ]



  function convertKeysToTitleCase(key: any) {
    const titleCaseKey = key
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .split(' ')?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase())
      .join(' ');
    return titleCaseKey;
  }

  useEffect(() => {
    onLoad()
  }, [callApi])


  const onLoad = () => {
    setLoader(true)
    apis.getEnquiry()
      .then((response: any) => {
        let arr: any = []
        response.data.data.map((item: any, index: any) => {
          if (!item?.user_id) {
            arr.push({
              id: item.id,
              name: item.name,
              email: item.email,
              mobile: item.mobile,
              organisation: item.organization
            })
          }
        })
        setTableData(arr)
        setLoader(false)

      })
      .catch((error) => {
        // setLoad(false)
        setLoader(false)
      });
  }



  const onLoadRoles = () => {
    setLoader(true)
    apis.getRoles()
      .then((response: any) => {



      })
      .catch((error) => {
        // setLoad(false)
        setLoader(false)
      });
  }

  const onUserActivateDisable = (
    // id: any, is_active: any
  ) => {
    setLoader(true);

    const body = {
      "id": editBuyer?.id,
      "is_active": editBuyer?.is_active ? false : true
    };

    apis.updateUsers(editBuyer?.id, body)
      .then((response: any) => {
        if (response?.data?.status == "success") {
          setOpen(false);
          setEdit(false);
          setCallApi(!callApi)
        }
      })
      .catch((error) => {
        setOpen(false);
        setEdit(false);

      })
      .finally(() => {
        setLoader(false);
      });
  }











  const columns: any = [

    { field: 'name', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'email', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'mobile', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'organisation', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } }
    // { field: 'role', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    // {
    //   field: 'asset_listed',
    //   render: (_: any, item: any) => (
    //     <div style={{
    //       width: "92px",
    //       height: "37px",
    //       margin: 0,
    //       padding: "8px 12px 8px 12px",
    //       gap: "10px",
    //       fontFamily: "Inter",
    //       fontSize: "14px",
    //       fontWeight: 500,
    //       lineHeight: "21px",
    //       textAlign: "center",
    //     }}>
    //       {item?.asset_listed}
    //     </div>
    //   ),
    //   cellStyle: {
    //     fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500",
    //     width: '100px',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     alignItems: "center",
    //   }
    // },
    // {
    //   field: 'status',

    //   render: (_: any, item: any) => (
    //     <div style={{
    //       width: "92px",
    //       height: "37px",
    //       padding: "8px 12px 8px 12px",
    //       gap: "10px",
    //       borderRadius: "50px",
    //       fontFamily: "Inter",
    //       fontSize: "14px",
    //       fontWeight: 500,
    //       lineHeight: "21px",
    //       textAlign: "center",

    //       backgroundColor: item?.status?.toLowerCase() == "active" ? "rgba(225, 255, 238, 1)" : "rgba(242, 242, 242, 1)",
    //       color: item?.status?.toLowerCase() == "active" ? "rgba(33, 150, 83, 1)" : ""
    //     }}>
    //       {item.status}
    //     </div>
    //   ),
    //   cellStyle: {
    //     height: "30px", width: '80px', fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500",
    //     '& .MuiTableCell-root': {
    //       height: "60px",
    //       padding: '10px'
    //     }
    //   }
    // },
    // {
    //   field: 'actions',
    //   render: (_: any, item: any) => (
    //     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: "center" }}>
    //       <button
    //         onClick={() => { alert("Clicked") }}
    //         style={{
    //           width: "32px",
    //           height: "32px",
    //           padding: "0px 0px 0px 0px",
    //           gap: "10px",
    //           borderRadius: "8px",
    //           backgroundColor: "rgba(77,77,77)",
    //           display: 'flex',
    //           flexDirection: 'column',
    //           justifyContent: 'center',
    //           alignItems: "center"
    //         }}
    //       >
    //         <img src={FileImage} alt="Delete Icon" color='#FFF' width={20} height={20} />
    //       </button>
    //       <UserActions editData={editBuyer} setEditData={setEditBuyer} data={item} setOpen={setOpen} setEdit={setEdit} setPopuptype={setPopuptype} menuOptions={menuOptions} onUserActivateDisable={onUserActivateDisable} />
    //     </div >
    //   ),
    //   cellStyle: {
    //     padding: 0, width: '120px', height: "30px", border: "0px solid transparent",
    //     flexDirection: 'row', alignItems: 'center', justifyContent: "center",
    //     '& .MuiTableCell-root': {
    //       height: "60px",
    //       padding: '10px',
    //       display: 'flex',
    //       flexDirection: 'row', alignItems: 'center', justifyContent: "center",
    //     }
    //   }
    // }

  ];






  const [selectedRole, setSelectedRole] = useState('');

  // const handleChangeRole = (event: any) => {
  //   setSelectedRole(event.target.value);
  // };

  const handleChangeTable = (event: any, item: any) => {
    const newRole = event.target.value;
    const newData: any = tableData.map((dataItem: any) =>
      dataItem.name === item.name ? { ...dataItem, role: newRole } : dataItem
    );
    setTableData(newData);
  };
  const [tableData, setTableData] = useState([]);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const paginatedData = tableData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });;



  const navigation = useNavigate();


  const linkDetails = useMemo(() => [
    {
      label: 'User Management',
      url: '/user_management',
    },
    {
      label: 'Zupotsu Unregistered',
      url: '/zupotsu_unregistered',
    },
  ], []);



  const ErrorData = useMemo(
    () => ({
      img: NoDataImage,
      button: false,
      message: "No Users Found"
    }),
    [
      // selectedCategory
    ]
  )


  const style = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (popuptype == "edit" || popuptype == "user") ? 600 : 500,
    backgroundColor: "#FFF",
    border: '0px solid #000',
    borderRadius: '8px',
    divShadow: 24,
    padding: "20px",
    fontFamily: 'Inter'
  };






  const handleSort = (key: any) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setTableData(sortedData);
  };



  const onSellerUpdation = () => {

    setLoader(true);

    if (popuptype === "edit") {
      const body = {
        "name": editBuyer?.name,
        "mobile": editBuyer?.mobile,
        "id": editBuyer?.id,
      };
      const id = editBuyer?.id;

      apis.updateUsers(id, body)
        .then((response: any) => {
          if (response?.data?.status == "success") {
            setOpen(false);
            setEdit(false);
            setCallApi(!callApi)
          }
        })
        .catch((error) => {
          setOpen(false);
          setEdit(false);

        })
        .finally(() => {
          setLoader(false);
        });
    }
  };





  if (!loader) {
    return (
      <Grid item xs={12} md={12} lg={12} sx={{ backgroundColor: 'rgb(250,250,250)', height: '90vh', overflowY: "scroll", overflowX: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            padding: '5px',
            backgroundColor: 'rgb(250,250,250)',
          }}
        >


          <Grid xs={12} md={12} lg={12} width={"98%"} spacing={2} marginTop={"10px"}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                backgroundColor: '#FFF',
                paddingTop: "15px",
                paddingBottom: "15px",
                padding: "15px",
                alignItems: 'center',
              }}
            >
              <Breadcrumb
                linkDetails={linkDetails}
                underline="always"
                maxItems={3}
                itemBeforeCollapse={1}
                itemAfterCollapse={1}
                iconName="arrow_forward_ios_black_24dp"
                iconSize={20}
                iconLabel="Breadcrumb-Arrow-Right"
                iconStyle="regular"
                color="#333"
                textColor="#333"
              />
            </Box>
          </Grid>

          <div style={{ width: '98%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "#FFF", paddingBottom: 10 }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginTop: '10px', marginBottom: '10px', backgroundColor: '#FFF' }}>
              {/* <div style={{ width: '20%' }}> */}
              <Typography
                sx={{
                  fontFamily: 'BebasNeue',
                  fontWeight: 500,
                  lineHeight: '30px',
                  fontSize: '20px',
                  textTransform: 'capitalize',
                }}
              >
                Unregistered List
              </Typography>
              {/* </div> */}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '20px',
                  // width: '70%',
                }}
              >
                <div style={{
                  padding: 0, margin: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: "8px"
                }}>

                  <TextField
                    // className={classes.searchInput}
                    placeholder="Search..."
                    sx={{
                      height: '40px',
                      '& .MuiFormControl-root': {
                        height: '40px',
                      },
                      '& .MuiTextField-root': {
                        height: '40px',
                      },
                      '& .MuiInputBase-root': {
                        height: '40px',
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        border: "0px solid none"
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: "0px solid none"
                      },

                      '& .MuiOutlinedInput': {
                        border: "0px solid none"
                      },
                      width: '200px',
                      backgroundColor: 'rgba(242, 242, 242, 1)',
                      border: "0px solid none",
                      borderRadius: '4px'

                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={SearchNormal}
                            alt="Search"
                            style={{
                              // paddingBottom: '5px',
                              marginRight: '10px',
                              height: '20px',
                              cursor: 'pointer',
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* <Button
                    sx={{
                      padding: '6px 8px',
                      color: '#FFF',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      textTransform: 'capitalize',
                      background: '#E20B18',
                      width: '126px',
                      border: "none",
                      height: '40px',

                      '&:hover': {
                        backgroundColor: '#a9141d',
                        color: '#fff',
                      },
                    }}
                    onClick={() => { setOpen(true); setPopuptype("user"); setEdit(false) }}
                  >
                    <span style={{ marginRight: '8px' }}>+</span>Add 
                  </Button> */}



                </div>


              </div>
            </div>




            <div style={{ borderColor: '#FFF', borderStyle: 'solid', borderWidth: '0px', width: '98%' }}>




              {tableData?.length > 0 ? (<>



                <UserManagementTable
                  columns={columns}
                  tableData={tableData}
                  setTableData={setTableData}
                  headers={headers}
                  handleChange={handleChangeTable}
                  search={""}
                />
              </>



              ) : (
                <NoData ErrorData={ErrorData} />
              )}

            </div>
          </div>


          {/* <Modal
            open={open}
            onClose={handleClose}

          >


            <Box sx={style}>

              {(popuptype !== "sendpasswordlink") && (<div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                paddingBottom: '10px'
              }}>
                <Typography
                  sx={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '22.4px',
                    textAlign: 'left',
                  }}
                >
                  {popuptype == "user" ? "Add User" : popuptype == "disableuser" ? "Disable User" : popuptype == "changerole" ? "Change Role" : popuptype == "edit" ? "Edit" : "Remove User"}
                </Typography>
                <Close
                  style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  onClick={handleClose}
                />
              </div>)}


              {(popuptype == "disableuser") && (
                <Typography sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "22.4px",
                  textAlign: "center",
                  margin: '30px',
                  marginTop: '40px',
                }}>
                  Are you sure you
                  want to permanent delete  <span style={{ color: "rgba(226, 11, 24, 1)" }}>{editBuyer?.name}?</span>
                </Typography>)}

              {(popuptype == "changerole") && (<Box sx={{ width: '100%', display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: 'flex-start', gap: '10px', marginTop: '25px' }}>
                <ZupotsuTextfield
                  title={"Current Role"}
                  placeholder={"Current Role"}
                  value={currentUser as string}
                  previewMode={true}
                  isRequired={false}
                  type={"text"}
                  name={"currentrole"}
                  multiline={false}
                  handleChange={(e: any) => { setCurrentUser(e.target.value) }}
                // maxLength={10}
                />
                <ZupotsuDropdown
                  title={"Select New Role"}
                  placeholder={"Select New Role"}
                  value={newUser as string}
                  isRequired={false}
                  name={"newrole"}
                  dropdownData={[
                    "Buyer"
                  ]}
                  handleChange={(e: any) => { setnewUser(e.target.value) }}
                // maxLength={10}
                />
                <ZupotsuTextfield
                  title="Please enter the reason"
                  placeholder={"Enter Reason"}
                  value={reason}
                  isRequired={true}
                  type={"text"}
                  name={"reason"}
                  multiline={true}
                  handleChange={(event: any) => {
                    setReason(event.target.value)
                  }}
                  rows={3}
                  maxLength={400}
                />
              </Box>)}


              {((popuptype == "edit") || (popuptype == "user")) && (
                <>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', gap: '10px', marginTop: '0px' }}>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "22.4px",
                        textAlign: "left",
                        margin: '10px',
                        marginLeft: "0px",
                        marginBottom: '5px'
                      }}
                    >
                      Buyer Details
                    </Typography>
                    {(popuptype == "edit") ? (<button
                      onClick={() => { setEdit(!edit) }}
                      style={{
                        gap: "8px",
                        borderRadius: "5px",
                        border: "0px solid rgba(189, 189, 189, 1)",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly',
                      }}>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "21px",
                          textAlign: "left",
                          color: "rgba(130,130,130,0.9)",
                          margin: 0
                        }}
                      >Edit Details</p>
                    </button>) : (<></>)}
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'space-evenly', alignItems: 'center', gap: '10px', marginTop: '15px' }}>
                    <div style={{ display: 'flex', width: '50%', flexDirection: "row", justifyContent: 'space-evenly', alignItems: 'center' }}>
                      <ZupotsuTextfield
                        title={"Name"}
                        placeholder={"Enter name"}
                        value={editBuyer["name"] as string}
                        isRequired={false}
                        type={"text"}
                        name={"name"}
                        multiline={false}
                        handleChange={(e: any) => { handleEdit(e) }}
                        previewMode={edit}
                      // maxLength={10}
                      />
                    </div>
                    <div style={{ display: 'flex', width: '50%', flexDirection: "row", justifyContent: 'space-evenly', alignItems: 'center' }}>
                      <ZupotsuTextfield
                        title={"Mobile No"}
                        placeholder={"Enter mobile no"}
                        value={editBuyer["mobile"] as string}
                        isRequired={false}
                        type={"text"}
                        name={"mobile"}
                        multiline={false}
                        handleChange={(e: any) => { handleEdit(e) }}
                        previewMode={edit}
                        maxLength={10}
                      />
                    </div>
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'space-evenly', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                    <div style={{ display: 'flex', width: '50%', flexDirection: "row", justifyContent: 'space-evenly', alignItems: 'center' }}>
                      <ZupotsuTextfield
                        title={"Email"}
                        placeholder={"Enter email"}
                        value={editBuyer["email"] as string}
                        isRequired={false}
                        type={"text"}
                        name={"email"}
                        multiline={false}
                        previewMode={popuptype == "edit" ? true : edit}
                        handleChange={(e: any) => { handleEdit(e) }}
                      // maxLength={10}
                      />
                    </div>
                    <div style={{ display: 'flex', width: '50%', flexDirection: "row", justifyContent: 'space-evenly', alignItems: 'center' }}>

                     
                    </div>

                  </Box>
                </>
              )}



              {(popuptype == "deleteuser") && (
                <Box sx={{ width: '100%', display: 'flex', flexDirection: "column", justifyContent: 'flex-start', alignItems: 'flex-start', gap: '10px', marginTop: '25px' }}>
                  <Typography sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "22.4px",
                    textAlign: "center",
                    marginBottom: '10px'

                  }}>
                    Are you sure you
                    want to permanent delete  <span style={{ color: "rgba(226, 11, 24, 1)" }}>{editBuyer?.name}?</span>
                  </Typography>
                  <ZupotsuTextfield
                    title="Please enter the reason"
                    placeholder={"Enter Reason"}
                    value={reason}
                    isRequired={true}
                    type={"text"}
                    name={"reason"}
                    multiline={true}
                    handleChange={(event: any) => {
                      setReason(event.target.value)
                    }}
                    rows={3}
                    maxLength={400}
                  />
                </Box>
              )}
              {(popuptype == "sendpasswordlink") ? (<></>) : (popuptype == "edit" && edit == true) ? (<></>) : (<div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '16px',
                  marginTop: '20px',
                  width: '100%',
                  paddingTop: '10px',
                  borderTop: '1px solid rgba(224, 224, 224, 1)',

                }}
              >

                <ZupotsuButton
                  name="Cancel"
                  variant={'outlined'}
                  padding={"10px 40px"}
                  isCustomColors={true}
                  customOutlineColor="0px solid #E0E0E0"
                  customOutlineColorOnHover="0px solid #E20B18"
                  customBgColorOnhover="rgba(226, 11, 24, 0.05)"
                  customBgColor="rgba(226, 11, 24, 0.05)"
                  customTextColorOnHover="rgba(226, 11, 24, 1)"
                  customTextColor="rgba(226, 11, 24, 1)"
                  handleClick={() => setOpen(false)}
                />
                <ZupotsuButton
                  name={popuptype == "changerole" ? "Submit" : popuptype == "user" ? "Submit" : popuptype == "disableuser" ? "Submit" : popuptype == "edit" ? "Update" : "Remove"}
                  variant={'contained'}
                  padding={"10px 40px"}
                  isCustomColors={true}
                  customOutlineColor="1px solid #E20B18"
                  customOutlineColorOnHover="1px solid #E20B18"
                  customBgColorOnhover="#E20B18"
                  customBgColor="#E20B18"
                  customTextColorOnHover="#FFF"
                  customTextColor="#FFF"
                  handleClick={() => {
                    if (popuptype == "disableuser") {
                      onUserActivateDisable()
                    } else {
                      onSellerUpdation()
                    }
                  }}
                />
              </div>)}


              {(popuptype == "sendpasswordlink") && (
                <Box sx={{

                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: "center",
                  width: "100%",
                  gap: '15px',
                  borderRadius: '8px'
                }}>
                  <img src={RoundedTickMark} style={{ width: "80px", height: '80px' }} />

                  <Typography sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "30px",
                    textAlign: "center"
                  }}>
                    Password Reset link has been sent successfully
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: '16px',
                      marginTop: '20px',
                      width: '100%',
                      paddingTop: '10px',
                      borderTop: '1px solid rgba(224, 224, 224, 1)'

                    }}
                  >
                    <ZupotsuButton
                      name="Okay"
                      variant={'contained'}
                      padding={"12px, 40px, 12px, 40px"}
                      isCustomColors={true}
                      customOutlineColor="1px solid #E20B18"
                      customOutlineColorOnHover="1px solid #E20B18"
                      customBgColorOnhover="#E20B18"
                      customBgColor="#E20B18"
                      customTextColorOnHover="#FFF"
                      customTextColor="#FFF"
                      handleClick={() => {
                        setOpen(false)
                      }}
                    />
                  </div>
                </Box>
              )}




            </Box>


          </Modal>
          <Modal
            open={popup}
            onClose={() => { setPopup(false) }}

          >

            <Box sx={{ ...style, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", width: 300, gap: '15px', borderRadius: '8px', }}>
              <img src={RoundedTickMark} style={{ width: "80px", height: '80px' }} />

              {popuptype == "editinguser" ? <Typography sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "30px",
                textAlign: "center",

              }}>User Details have been successfully edited</Typography>
                : <Typography sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "30px",
                  textAlign: "center",

                }}><span style={{ color: "rgba(226, 11, 24, 1)" }}>{editBuyer?.name}</span> has been successfully removed!</Typography>}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '16px',
                  marginTop: '20px',
                  width: '100%',
                  paddingTop: '10px',
                  borderTop: '1px solid rgba(224, 224, 224, 1)'

                }}
              >
                <ZupotsuButton
                  name="Okay"
                  variant={'contained'}
                  padding={"12px, 40px, 12px, 40px"}
                  isCustomColors={true}
                  customOutlineColor="1px solid #E20B18"
                  customOutlineColorOnHover="1px solid #E20B18"
                  customBgColorOnhover="rgba(226, 11, 24, 1)"
                  customBgColor="rgba(226, 11, 24, 1)"
                  customTextColorOnHover="#FFF"
                  customTextColor="#FFF"
                  handleClick={() => {
                    setPopup(false)
                  }}
                />
              </div>
            </Box>

          </Modal> */}
        </Box>

      </Grid >
    );
  }
  else {
    return (
      <div className="centered-container">
        <div className="loader"></div>
      </div>
    )
  }
};

export default ZupotsuUnregistered;
