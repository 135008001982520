import {
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
  MenuItem,
  Select,
  styled,
  Dialog,
  DialogContent,
  Snackbar,
  InputAdornment,
  TextField,
  Paper,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
  Table,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import Breadcrumb from '../../Atoms/breadcrumb/breadcrumb'
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Loader from '../../loader/Loader';
import useDeviceType from '../../utils/DeviceType';
import { DeleteIcon, EditIconn, LinearGrid, List, NoDataImage, SearchNormal } from '../../assets';
import NoData from '../../error/NoData';
import { VisibilityOutlined } from '@mui/icons-material';
import ReusableTable from '../../Molecules/table-management/ReusableTable';
import ActionButton from '../../Molecules/table-management/ActionButton';
import VisibilityButton from '../../Atoms/Visibility/VisibilityButton';
import { useNavigate } from 'react-router-dom';
import Apis from '../../services/apis';
const Newrequests = () => {
  const apis = new Apis();
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [loader, setLoader] = useState(false);
  const headers = [ "Asset Type", "Sport", "Asset Name", "Opportunities", "Source","Buyer Name", "View"];
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const [tableData, setTableData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const deviceType = useDeviceType();
  const ErrorData = useMemo(
    () => ({
      img: NoDataImage,
      button: false,
      message: 'No assets found',
    }),
    []
  );

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const response = await apis.getAllProposals();
        let arr: any = [];
        response?.data?.data.map((item: any, index: any) => {
          arr.push(
            {
              "id":item.id,
              "buyerName": item?.buyer?.name || "",
              "assetType": item?.asset_type,
              "sport": item?.sports[0],
              "assetName": item?.buyer?.name || "",
              "opportunities": item?.opportunities[0]?.opportunity,
              "source": item?.source,
              "action": "Edit"
            }
          )
        })
        setTableData(arr);
      } catch (error) {
        console.error("Error fetching proposals:", error);
      }
    };

    fetchProposals();
  }, []);


  const linkDetails = useMemo(() => {
    return [
      {
        label: 'Proposal Management',
        url: '',
      },
      {
        label: 'Proposal Requests',
        url: '',
      }
    ];
  }, []);

  const handleChangeTable = (event: any, item: any) => {
    const newRole = event.target.value;
    const newData: any = tableData.map((dataItem: any) =>
      dataItem.name === item.name ? { ...dataItem, role: newRole } : dataItem
    );
    setTableData(newData);
  };


  const handleInputChangeFun = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };
  const navigation = useNavigate()

  const columns: any = [
   
    { field: 'assetType', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'sport', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'assetName', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'opportunities', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'source', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'buyerName', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    {
      field: 'actions',
      render: (_: any, item: any) => (

        <div onClick={() => { navigation(`/allleadsproposaldetails?id=${item?.id}`) }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center", padding: 0, border: "0px solid transparent", backgroundColor: "rgba(226, 11, 24, 1)", width: '32px', height: '32px', borderRadius: '8px' }}>
          <VisibilityButton onVisibility={false} />
        </div>
      ),
      cellStyle: { padding: 0, border: "0px solid transparent", width: '50px', paddingLeft: '10px' }
    }

  ];


  return (
    <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: "center", backgroundColor: 'rgba(250,250,250,1)', height: '90vh', overflowY: "scroll", scrollbarWidth: 'none', overflowX: 'hidden' }}>
      <Grid xs={12} md={12} lg={12} width={"100%"} spacing={2} sx={{ backgroundColor: 'rgba(250,250,250,1)' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: '#FFF',
            margin: "10px",
            padding: "15px",
            width: '98%',
            alignItems: 'center',
          }}
        >
          <Breadcrumb
            linkDetails={linkDetails}
            underline="always"
            maxItems={3}
            itemBeforeCollapse={1}
            itemAfterCollapse={1}
            iconName="arrow_forward_ios_black_24dp"
            iconSize={20}
            iconLabel="Breadcrumb-Arrow-Right"
            iconStyle="regular"
            color="#333"
            textColor="#333"
          />
        </Box>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity as AlertColor}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      <div
        style={{
          width: '100%',
          padding: "10px",

          marginBottom: '10px'
        }}
      >

        <div
          style={{
            padding: '14px 10px 14px 10px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#FFF",
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              flexWrap: "wrap"
            }}
          >
            <Typography
              sx={{
                color: '#333',
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '22px',
              }}
            >
              Proposal Requests
            </Typography>



            {(deviceType == "mobile") && (<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            </div>)}


            <div style={{
              padding: 0, margin: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: deviceType === "mobile" ? "center" : 'flex-end',
              alignItems: 'center',
              gap: "8px",
              flexWrap: "wrap",
              width: deviceType == "mobile" ? "100%" : '',
            }}>

              <TextField
                placeholder="Search..."
                onChange={handleInputChangeFun}
                sx={{
                  height: '40px',
                  '& .MuiFormControl-root': {
                    height: '40px',
                    border: "0px solid transparent",
                  },
                  '& .MuiTextField-root': {
                    height: '40px',
                    border: "0px solid transparent",
                  },
                  '& .MuiInputBase-root': {
                    height: '40px',
                    border: "0px solid transparent",
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                    border: "0px solid transparent"
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: "0px solid transparent"
                  },

                  '& .MuiOutlinedInput': {
                    border: "0px solid transparent"
                  },
                  width: deviceType == "mobile" ? "100%" : '200px',
                  backgroundColor: 'rgba(242, 242, 242, 1)',
                  border: "0px solid transparent",
                  borderRadius: '4px'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchNormal}
                        alt="Search"
                        style={{
                          // paddingBottom: '5px',
                          marginRight: '10px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />



            </div>


          </div>

          {loader && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '45vh',
                left: '50%',
                position: 'absolute',
              }}
            >
              <Loader status={loader} size={50} />
            </div>
          )}

        </div>
        {
          !loader && (
            <div style={{ marginTop: '20px' }}>
              {
                tableData?.length > 0 ? (
                  <>

                    <ReusableTable
                      columns={columns}
                      tableData={tableData}
                      setTableData={setTableData}
                      headers={headers}
                      handleChange={handleChangeTable}
                    />
                  </>
                ) : (
                  <NoData ErrorData={ErrorData} />

                )}
            </div>
          )}


      </div>

    </div>
  )
}

export default Newrequests
