import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Modal,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Close, ArrowBack, } from '@mui/icons-material';
import ZupotsuTextfield from '../../Components/Settings/ZupotsuTextfield';
import ZupotsuDropdown from '../zupotsu-dropdown/zupotsu-dropdown';
import { documentUpload } from '../../assets';
import ZupotsuButton from '../zupotsu-button/zupotsu-button';
import Apis from '../../services/apis';
import useDeviceType from '../../utils/DeviceType';
import ZupotsuImgUpload from '../zupotsu-img-upload/zupotsu-img-upload';




export function ZoputsuGetInTouch1({ showZoputsuGetInTouchPopup, closePopup }: any) {
  const [uploadDocument, setUploadDocument] = React.useState<any>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [firstName, setFirstName] = useState(localStorage.getItem("name") || "")
  const [emailAddress, setEmailAddress] = useState(localStorage.getItem("email") || "")
  const [organisationName, setOrganisationName] = useState(localStorage.getItem("org") || "")
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("mobile") || "")
  const [sports, setSports] = useState("")
  const [aboutYourself, setAboutYourSelf] = useState('')
  const [assetName, setAssetName] = useState('')
  const [opportunities, setOpportunities] = useState('')
  const [assetType, setAssetType] = useState('')
  const [allsports, setAllSports] = useState([])
  const [fileData, setFileData] = React.useState<any>([]);
  const userFromLocal = localStorage.getItem("role")?.toLowerCase();
  const isItAdmin = (userFromLocal === "admin") ? true : false;
  const isSellerAdmin = (userFromLocal === "seller-admin") ? true : false;
  const isApprover = (userFromLocal === "approver") ? true : false;
  const isPublisher = (userFromLocal === "publisher") ? true : false;
  const isSeller = (userFromLocal === "seller") ? true : false;
  const isBuyer = (userFromLocal === "buyer") ? true : false;
  const [typeofhelp, setTypeofHelp] = useState("")
  const [load, setLoad] = useState<any>(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const deviceType = useDeviceType()
  const apis = new Apis();
  useEffect(() => {
    apis.getAllPrimaryAttributesSports()
      .then((response: any) => {
        if (response?.data?.status === "success") {
          const fetchedArray = response.data.data || [];
          const sports = fetchedArray.filter((item: any) => item?.toLowerCase() !== "all")
          setAllSports(sports || [])
        }
      })
      .catch((error: any) => {

      });
  }, []);
  const handleFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name;
      const file = e.target.files[0]
      if (file.type === 'application/pdf') {
        try {
          const res = await apis.getS3URL(file);
          console.log("resres", res)
          setFileData(res.data.data[0])
          setSnackbar({
            open: true,
            severity: 'success',
            message: 'File uploaded successfully',
          });
        } catch (error) {
          setSnackbar({
            open: true,
            severity: 'error',
            message: 'Something went wrong!',
          });
        }
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please upload a valid PDF file',
        });
      }
      setUploadDocument(fileName);

    }
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 8,
    borderRadius: "8px",
    p: 0,
  };


  const userId: any = localStorage.getItem("userID")
  const login: boolean = localStorage.getItem("accessToken") ? true : false

  const handleEnquiry = async () => {
    setLoad(true);
    const body: any = {
      "requirement": aboutYourself,
      "requirement_doc": fileData?.length > 0 ? fileData :  null,
      "user_id": parseInt(userId) || null,
      "name": firstName,
      "email": emailAddress,
      "mobile": phoneNumber,
      "organization": organisationName,
      "help_type": typeofhelp,

    };

    try {
      const response = await apis.postEnquiry(body);
      if (response.data?.status === "success") {
        closePopup()
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Something went wrong',
        severity: 'error',
      });
    } finally {
      setLoad(false);
    }
  };

  return (
    <Modal
      open={showZoputsuGetInTouchPopup}
      onClose={closePopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>

        <Box sx={{
          width: "100%",
          height: "auto",
          display: 'flex',
          flexDirection: "row",
          padding: "8px 16px 8px 16px",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          justifyContent: "space-between",
        }} >
          <p style={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
            textAlign: "left",
            padding: 0,
            margin: 0,
            color: "rgba(51, 51, 51, 1)"
          }}>
            {/* Request for Proposal */}
            Request For Help
          </p>

          <Close
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => { closePopup() }}
          />
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          // overflowX: 'hidden',
          scrollbarWidth: 0,
          padding: '10px'
        }} >



          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: deviceType == "mobile" ? "column" : "row",
            justifyContent: deviceType == "mobile" ? "flex-start" : "space-between",
            gap: '10px',
            padding: "8px 16px 8px 16px",
            marginTop: '0px'
          }} >
            <ZupotsuTextfield
              title="Full Name"
              isRequired={true}
              placeholder={"First Name"}
              value={firstName}
              type={"text"}
              name={"firstname"}
              multiline={false}
              handleChange={(event: any) => {
                setFirstName(event.target.value)
              }}
            />

            <ZupotsuTextfield
              title="Email Address"
              isRequired={true}
              placeholder={"Tell us about your team in few words"}
              value={emailAddress}
              type={"text"}
              name={"email"}
              multiline={false}
              handleChange={(event: any) => {
                setEmailAddress(event.target.value)
              }}
            />
          </Box>

          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: deviceType == "mobile" ? "column" : "row",
            justifyContent: deviceType == "mobile" ? "flex-start" : "space-between",
            gap: '10px',
            padding: "8px 16px 8px 16px",
            marginTop: '0px'
          }} >
            <ZupotsuTextfield
              title="Organisation Name"
              isRequired={true}
              placeholder={"Organisation name"}
              value={organisationName}
              type={"text"}
              name={"organisationname"}
              handleChange={(event: any) => {
                setOrganisationName(event.target.value)
              }}
            />
            <ZupotsuTextfield
              title="Phone"
              isRequired={true}
              placeholder={"+91 9876543211"}
              value={phoneNumber}
              type={"text"}
              name={"phone"}
              handleChange={(event: any) => {
                setPhoneNumber(event.target.value)
              }}
            />
          </Box>
          {/* <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: deviceType == "mobile" ? "column" : "row",
            justifyContent: deviceType == "mobile" ? "flex-start" : "space-between",
            gap: '10px',
            padding: "8px 16px 8px 16px",
            marginTop: '0px'
          }} >

            <ZupotsuDropdown
              title="Asset Type"
              placeholder={"Select Asset Type"}
              value={assetType?.toLowerCase()}
              name={"assettype"}
              dropdownData={["content", "team", "tournament", "athlete"]}
              handleChange={(event: any) => {
                setAssetType(event.target.value?.toLowerCase())
              }}
            />
            <ZupotsuDropdown
              title="Sports"
              placeholder={"Select sports"}
              value={sports}
              name={"sports"}
              dropdownData={allsports}
              handleChange={(event: any) => {
                setSports(event.target.value)
              }}
            />
          </Box> */}

          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: deviceType == "mobile" ? "column" : "row",
            justifyContent: deviceType == "mobile" ? "flex-start" : "space-between",
            gap: '10px',
            padding: "8px 16px 8px 16px",
            marginTop: '0px'
          }} >

            {(isSeller || isSellerAdmin) ? (<ZupotsuDropdown
              title="Select Help Type	"
              placeholder={"Select Help Type"}
              value={typeofhelp}
              name={"typeofhelp"}
              isRequired={true}
              dropdownData={["I need help listing an asset", "I have a general question", "I have listed an asset but don't see it on the catalogue", "I need some technical support"]}
              handleChange={(event: any) => {
                setTypeofHelp(event.target.value)
              }}
            />) : (isBuyer) ? (
              <ZupotsuDropdown
                title="Select Help Type"
                placeholder={"Select Help Type"}
                value={typeofhelp}
                name={"typeofhelp"}
                isRequired={true}
                dropdownData={["I am interested in a specific asset/ opportunity", "An asset/ opportunity that I am interested in is not listed", "I would like a recommendation from Zupotsu for my marketing needs", "I have a general question", "I need some technical support"]}
                handleChange={(event: any) => {
                  setTypeofHelp(event.target.value)
                }}
              />) : (
              <ZupotsuDropdown
                title="Select Help Type"
                placeholder={"Select Help Type"}
                value={typeofhelp}
                name={"typeofhelp"}
                isRequired={true}
                dropdownData={["I want help to list my asset on the platform", "I have a general question", "I have listed an asset but don't see it on the catalogue", "I need some technical support	", "I am interested in a specific asset/ opportunity", "An asset/ opportunity that I am interested in is not listed", "I would like a recommendation from Zupotsu for my marketing needs"]}
                handleChange={(event: any) => {
                  setTypeofHelp(event.target.value)
                }}
              />
            )}
          </Box>

          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: "row",
            justifyContent: "space-between",
            gap: '10px',
            padding: "8px 16px 8px 16px",
            marginTop: '0px'
          }} >
            <ZupotsuTextfield
              title="Tell us about your requirement"
              placeholder={"Enter Reason"}
              value={aboutYourself}
              type={"text"}
              name={"Tell us about your requirement"}
              multiline={true}
              rows={3}
              handleChange={(event: any) => {
                setAboutYourSelf(event.target.value)
              }}
            />
          </Box>

          {(login) && (<Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: '10px',
            padding: "8px 16px 8px 16px",
            marginTop: '0px'
          }} >

            <Typography
              style={{
                marginBottom: '0px',
                color: 'var(--Gray-1, #333)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                lineHeight: '140%',
                display: 'flex',
                flexDirection: 'column',
                fontWeight: '600'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    fontStyle: 'Inter',
                    fontWeight: '600',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: "21px",
                      fontStyle: 'Inter',
                      fontWeight: '700',
                    }}
                  >{"Upload Document"}</span>


                </div>

              </div>

            </Typography>

            <TextField
              rows={1}
              size="small"
              placeholder={"Browse"}
              fullWidth
              value={uploadDocument}
              disabled={false}
              name={'uploaddocument'}
              id="fullWidth"
              onClick={handleBrowseClick}
              sx={{
                color: "#000",
                background: 'transparent',
                cursor: 'pointer',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={documentUpload}
                      alt=""
                      style={{
                        width: '24px',
                        height: '24px',
                      }}
                    />
                  </InputAdornment>
                ),
                // readOnly: true,
              }}
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />


          </Box>)}
        </Box>

        <Box sx={{
          width: "100%",
          height: "auto",
          display: 'flex',
          flexDirection: "row",
          alignItems: 'center',
          padding: "8px 16px 8px 16px",
          // borderTop: "1px solid rgba(224, 224, 224, 1)",
          justifyContent: "center",
          marginTop: '0px',
          // boxShadow: " 0px 0px 14px 0px rgba(0, 0, 0, 0.07)"
        }} >
          <ZupotsuButton
            name={"Submit"}
            variant={'contained'}
            padding={"10px 40px"}
            isCustomColors={true}
            customOutlineColor="1px solid transparent"
            customOutlineColorOnHover="1px solid transparent"
            customBgColorOnhover="#E20B18"
            customBgColor={"#E20B18"}
            customTextColorOnHover="#FFF"
            customTextColor="#FFF"
            handleClick={
              () => {
                if (firstName && emailAddress && organisationName && phoneNumber && typeofhelp) {
                  handleEnquiry()
                } else {
                  setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please fill all mandatory fields',
                  });
                }
              }
            }
          />
        </Box>
      </Box>
    </Modal>

  );
};
export default ZoputsuGetInTouch1;
