import { Button, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import ZupotsuTextfield from '../../Atoms/zupotsu-textfields/zupotsu-textfields';
import ZupotsuButton from '../../Atoms/zupotsu-button/zupotsu-button';
import './zupotsu-login.css';
import useDeviceType from '../../utils/DeviceType';
import { validateEmail } from '../../utils/validateTextfieldValue';
import { useNavigate } from 'react-router-dom';
import Apis from '../../services/apis';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

export function ZupotsuLogin({ setSortAssets }: any) {
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const deviceType = useDeviceType();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const apis = new Apis();
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);

  const loginFun = () => {
    if (email && password) {
      if (!validateEmail(email)) {
        setIsEmailValid(validateEmail(email));
        return;
      }
      else {
        setLoader(true)
        let body = {
          "email": email?.toLowerCase(),
          "password": password
        }
        apis.login(body)
          .then((response: any) => {
            localStorage.clear()
            localStorage.setItem("userID", response.data.data.id)
            localStorage.setItem("orgID", response.data.data.organization_id)
            localStorage.setItem("org", response.data.data.organization.name)
            localStorage.setItem("accessToken", response.data.data.access_token)
            localStorage.setItem("role", response.data.data.userroles[0].role.name)
            localStorage.setItem("name", response.data.data.name)
            localStorage.setItem("mobile", response.data.data.mobile)
            localStorage.setItem("email", response.data.data.email)
            localStorage.setItem("preferred_currency", response.data.data.preferred_currency)
            setLoader(false)
            if (!response.data.data.userroles[0].role.name.includes("buyer")) { navigate('/catalogue-management') }
            else {
              navigate('/catalogue')
            }

          })
          .catch((error) => {
            // setLoad(false)
            setLoader(false)
            setSnackbar({
              open: true,
              severity: 'error',
              message: error?.response?.data?.error || 'something went wrong!!',
            });
          });
      }
    }
    else {
      if (!email) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please enter mail id!!',
        });
      } else if (!password) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Password is missing!!',
        });
      }

    }
  };

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  return (
    <>

      <div
        className="hide-scroll-stepper"
        style={{
          height: deviceType === 'mobile' ? '220px' : 'auto',
          overflowY: 'auto',
        }}
      >
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbar.severity as AlertColor}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>

        <div style={{ width: '100%' }}>

          <div style={{ paddingTop: '20px' }}>
            <ZupotsuTextfield
              title="Email"
              type="email"
              value={email?.toLowerCase()}
              isRequired={true}
              placeholder={
                deviceType === 'mobile' ? 'Email' : 'Official Email'
              }
              errorMessage={
                !isEmailValid
                  ? 'Please enter a valid email. E.g. example@email.com'
                  : ''
              }
              onKeyDown={(e: any) => {
                if (e.key == "Enter") {
                  loginFun()
                }
              }}
              handleChange={(e) => {
                setEmail(e.target.value?.toLowerCase())
                setIsEmailValid(true)
              }}
            />
          </div>

          <div style={{ paddingTop: '20px' }}>
            <ZupotsuTextfield
              title="Password"
              type="password"

              value={password}
              placeholder="Password"
              isRequired={true}
              handleChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key == "Enter") {
                  loginFun()
                }
              }}
            />
          </div>
        </div>


      </div>

      <div style={{ paddingTop: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            onClick={() => { navigate("/forgotpassword") }}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "19.6px",
              textAlign: "right",
              marginTop: '10px',
              marginBottom: '20px',
              color: '#111',
              textTransform: 'capitalize',
              padding: 0,
              backgroundColor: "transparent"
            }}
          >
            Forgot Password?
          </Button>
        </div>
        <ZupotsuButton
          disabled={!password.trim() || !email.trim()}
          handleClick={(action: string) => loginFun()}
          name="Sign In"
          customBgColor={(!password.trim() || !email.trim()) ? "rgba(226, 11, 24, 0.3)" : "rgba(226, 11, 24, 1)"}
          customTextColor={"#FFF"}
          customBgColorOnhover={"rgba(226, 11, 24, 0.3)"}
          customTextColorOnHover={"1px solid rgba(189, 189, 189, 1)"}
          customOutlineColor={"1px solid rgba(189, 189, 189, 1)"}
          load={loader ? true : false}
        />

        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "19.6px",
            textAlign: "right",
            marginTop: '10px',
            marginBottom: '20px',
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >

          <div style={{ margin: 0, padding: 0, fontFamily: "Inter", fontSize: "13px", }}>New User ? </div>
          <Button
            onClick={() => { setSortAssets("Register") }}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "19.6px",
              textAlign: "right",
              color: '#111',
              margin: 0,
              textTransform: 'capitalize',
              padding: 0,
              backgroundColor: "transparent",
              paddingLeft: '5px'
            }}
          >  Register here
          </Button>
        </Typography>
      </div>
    </>
  );
}

export default ZupotsuLogin;
