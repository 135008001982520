import {
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
  MenuItem,
  Select,
  styled,
  Dialog,
  DialogContent,
  Snackbar,
  InputAdornment,
  TextField,
  Paper,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
  Table,
  ListItemText,
  Menu, MenuList,
  Modal
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import documentupload from './../../assets/documentupload.svg'
import Breadcrumb from '../../Atoms/breadcrumb/breadcrumb'
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Loader from '../../loader/Loader';
import useDeviceType from '../../utils/DeviceType';
import { arrowUp, collapse, DeleteIcon, DocumentDownloadIcon, documentUpload, download, Edit, EditIconn, expand, LinearGrid, List, NoDataImage, Pinned, Search, SearchNormal, tickCircle, TickCircleGreen, touch2 } from '../../assets';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';
import Stepper from '@mui/material/Stepper';
import Close from '@mui/icons-material/Close';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ZupotsuTextfield from '../Settings/ZupotsuTextfield';
import ZupotsuDropdown from '../../Atoms/zupotsu-dropdown/zupotsu-dropdown';
import VisibilityButton from '../../Atoms/Visibility/VisibilityButton';
import ZupotsuButton from '../../Atoms/zupotsu-button/zupotsu-button';
import addSquare from './../../assets/addSquare.svg'
import menubase from './../../assets/menubase.svg'
import smile from './../../assets/smile.svg'
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import sendbutton from './../../assets/sendbutton.svg'
import Text from './chatatoms/Text';
import Apis from '../../services/apis';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const steps = [
  {
    label: 'Enquiry Initiated',
    buttonName: 'Generate lead'
  },
  {
    label: 'Scope Finalisation',
    buttonName: 'Generate lead'
  }, {
    label: 'Quotation',
    buttonName: "Send Quotation"
  }, {
    label: 'Negotiation',
    buttonName: 'Start Negotiation'
  }, {
    label: 'Proposal',
    buttonName: 'Create Proposal'
  },
  {
    label: 'MSA Agreement',
    buttonName: 'Sign MSA'
  }, {
    label: 'Reporting',
    buttonName: 'Generate Report'
  },
  {
    label: 'Invoicing',
    buttonName: 'Generate'
  },
];
const Allleadproposaldetails = () => {
  const userFromLocal = localStorage.getItem("role")?.toLowerCase();
  const isItAdmin = (userFromLocal === "admin") ? true : false;
  const isSellerAdmin = (userFromLocal === "seller-admin") ? true : false;
  const isApprover = (userFromLocal === "approver") ? true : false;
  const isPublisher = (userFromLocal === "publisher") ? true : false;
  const isSeller = (userFromLocal === "seller") ? true : false;
  const isBuyer = (userFromLocal === "buyer") ? true : false;
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [buyerchats, setBuyerChats] = useState<any>({})
  const [sellerchats, setSellerChats] = useState<any>({})
  const [sellerName, setSellerName] = React.useState<string>('');
  const [buyerName, setBuyerName] = React.useState<string>('');
  const [loader, setLoader] = useState(false);
  const [clickDownload, setClickDownload] = useState(false)
  const [documentpopupOpen, setDocumentpopupOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState((isSeller || isSellerAdmin) ? 'Seller' : isBuyer ? 'Buyer' : 'Buyer');
  const [uploadDocument, setUploadDocument] = React.useState<string>('');
  const [documentSize, setDocumentSize] = React.useState<any>("");
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const [searchValue, setSearchValue] = useState('');
  const [isSearchPresent, setSearchPresent] = useState(false);
  const [isSearchSeller, setSearchSeller] = useState(false);
  const [isBuyerChatExist, setBuyerChatExist] = useState(false)
  const [isSellerChatExist, setSellerChatExist] = useState(false)
  const [proposalDetails, setproposalDetails] = useState<any>()
  const deviceType = useDeviceType();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => { setAnchorEl(null); };
  const handleClick = (event: any) => { setAnchorEl(event.currentTarget) };
  const [buyerid, setBuyerid] = useState("")
  const [buyerorgid, setBuyerorgid] = useState("")
  const [buyerloading, setBuyerLoading] = useState(false)
  const [sellerid, setSellerid] = useState("")
  const [sellerorgid, setSellerorgid] = useState("")
  const [sellerloading, setSellerLoading] = useState(false)
  const [buyerChatBoxId, setBuyerChatBoxId] = useState("")
  const [sellerChatBoxId, setSellerChatBoxId] = useState("")
  const apis = new Apis();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') ?? '';
  const [fileData, setFileData] = React.useState<any>([]);
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const ErrorData = useMemo(
    () => ({
      img: NoDataImage,
      button: false,
      message: 'No assets found',
    }),
    []
  );
  useEffect(() => {
    scrollToBottom()
  }, [buyerchats, sellerchats])



  const divRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };




  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name;
      const file = e.target.files[0]
      const fileSizeInKB = file.size / 1024;
      const fileSizeInMB = file.size / 1024 / 1024;
      const fileSize = fileSizeInMB > 1
        ? `${fileSizeInMB.toFixed(2)} MB`
        : `${fileSizeInKB.toFixed(2)} KB`;
      if (file.type === 'application/pdf') {
        try {
          const res = await apis.getS3URL(file);
          console.log("resres", res)
          setFileData(res.data.data[0])
          setSnackbar({
            open: true,
            severity: 'success',
            message: 'File uploaded successfully',
          });
        } catch (error) {
          setSnackbar({
            open: true,
            severity: 'error',
            message: 'Something went wrong!',
          });
        }
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please upload a valid PDF file',
        });
      }
      setDocumentSize(fileSize)
      setUploadDocument(fileName);

    }
  };


  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  useEffect(() => {
    setLoader(true)
    const fetchProposals = async () => {
      try {
        const response = await apis.getAllProposalById(id);
        const proposals = response?.data?.data;
        setproposalDetails(proposals)
        setBuyerid(proposals?.buyer_id)
        setBuyerorgid(proposals?.buyer_org_id)
        setSellerorgid(proposals?.seller_org_id)
        setSellerid(proposals?.seller_id)
        const index = steps.findIndex(step => step.label === proposals?.proposal_lead_status?.slice(-1)[0]?.name);
        setActiveStep(index !== 7 ? index + 1 : index)
        if (proposals) {
          try {
            const response = await apis.getAllChatsByProposalId(id);
            if (response?.data?.status) {
              setSellerChats(response?.data?.data?.seller_chats[0]?.chats || {})
              setBuyerChats(response?.data?.data?.buyer_chats[0]?.chats || {})
              if (response?.data?.data?.buyer_chats[0]?.chat_participants[0]?.participant?.name || "") {
                setBuyerName(response?.data?.data?.buyer_chats[0]?.chat_participants[0]?.participant?.name)
              }
              if (response?.data?.data?.seller_chats[0]?.chat_participants[0]?.participant?.name || "") {
                setSellerName(response?.data?.data?.seller_chats[0]?.chat_participants[0]?.participant?.name)
              }
              setSellerChatBoxId(response?.data?.data?.seller_chats[0]?.chat_participants[0]?.chat_box_id)
              setBuyerChatBoxId(response?.data?.data?.buyer_chats[0]?.chat_participants[0]?.chat_box_id)
              if (response?.data?.data?.buyer_chats?.length > 0) {
                setBuyerChatExist(true)
              }
              if (response?.data?.data?.seller_chats?.length > 0) {
                setSellerChatExist(true)
              }
              setLoader(false)
            }
          } catch (addChatError) {
            console.error("Error adding chat box:", addChatError);
            setSnackbar({
              open: true,
              severity: 'error',
              message: 'something went wrong!!'
            });
            setLoader(false)
          }
        }
      } catch (error) {
        console.error("Error fetching proposals:", error);
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'something went wrong!!'
        });
        setLoader(false)
      }
    };

    fetchProposals();
  }, [id]);



  const sortingStyles = {
    button: {
      background: '#E20B18',
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#a9141d',
        color: '#fff',
      },
      cursor: "pointer"
    },
    tabButton: {
      padding: deviceType == "mobile" ? "5px" : '10px',
      color: 'rgba(226, 11, 24, 1)',

      fontSize: deviceType == "mobile" ? "15px" : '16px',
      borderBottom: ' 2px solid rgba(226, 11, 24, 1)',
      fontFamily: 'Inter',
      fontWeight: 600,
      cursor: "pointer"
    },
    tabButtonInactive: {
      padding: deviceType == "mobile" ? "5px" : '10px',
      color: 'rgba(226, 11, 24, 1)',
      fontSize: deviceType == "mobile" ? "15px" : '16px',
      fontFamily: 'Inter',
      fontWeight: 500,
      cursor: "pointer"
    }
  };


  const linkDetails = useMemo(() => {
    return [
      {
        label: 'Proposal Mgmt',
        url: '/newrequests',
      },
      {
        label: 'RFP',
        url: '',
      }
    ];
  }, []);


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ?
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const [popupopen, setpopupOpen] = React.useState(false);
  const handleOpenpopup = () => setpopupOpen(true);
  const handleClosepopup = () => setpopupOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 8,
    borderRadius: 5,
    p: 0,
  };


  const AddChatBoxApi = async (individualid: any, individualorgid: any) => {
    setLoader(false)
    try {
      const body = {
        "proposal_id": parseInt(id),
        "chat_type": "individual",
        "chat_name": "individual",
        "chat_participants": [
          {
            "participant_id": individualid,
            "participant_org_id": individualorgid
          }
        ]
      }
      const response = await apis.addChatBox(body);
      if (response?.data?.status == "success") {
        // if (selectedRole == "Buyer") {
        //   setBuyerChatExist(true)
        // }
        // else if (selectedRole == "Seller") {
        //   setSellerChatExist(true)
        // }
        setBuyerLoading(false)
        setSellerLoading(false)
        chatUpdations()
        setLoader(false)
      }


    } catch (error) {
      console.error("Error fetching proposals:", error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'something went wrong!!'
      });
      setLoader(false)
    }
  };


  const AddProposalLeadStatus = async (label: any) => {
    setLoader(true)
    try {
      const body = {
        "proposal_id": parseInt(id),
        "name": label
      }
      const response = await apis.addProposalLeadStatus(body);
      if (response?.data?.status == "success") {
        handleNext()
        setLoader(false)
      }

    } catch (error) {
      console.error("Error fetching proposals:", error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'something went wrong!!'
      });
      setLoader(false)
    }
  };

  const chatUpdations = async () => {
    setLoader(true)
    try {
      const response: any = await apis.getAllChatsByProposalId(id);
      if (response?.data?.status) {
        setSellerChats(response?.data?.data?.seller_chats[0]?.chats);
        setBuyerChats(response?.data?.data?.buyer_chats[0]?.chats);
        if (response?.data?.data?.buyer_chats?.length > 0) {
          setBuyerChatExist(true)
        }
        if (response?.data?.data?.seller_chats?.length > 0) {
          setSellerChatExist(true)
        }
        if (response?.data?.data?.buyer_chats[0]?.chat_participants[0]?.participant?.name || "") {
          setBuyerName(response?.data?.data?.buyer_chats[0]?.chat_participants[0]?.participant?.name)
        }
        if (response?.data?.data?.seller_chats[0]?.chat_participants[0]?.participant?.name || "") {
          setSellerName(response?.data?.data?.seller_chats[0]?.chat_participants[0]?.participant?.name)
        }
      }
      setLoader(false)
    } catch (addChatError) {
      console.error("Error adding chat box:", addChatError);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'something went wrong!!'
      });
      setLoader(false)
    }
  };


  const ChatBody: any = {
    "chat_box_id": selectedRole == "Buyer" ? buyerChatBoxId : selectedRole == "Seller" ? sellerChatBoxId : "",
    "text": searchValue,
    "doc": null,
    "doc_name": null,
    "doc_size": null,
    "caption": null,
    // "is_accepted": false,
    "accepted_by": null,
    "forwarded": false,
    "forwarded_from": null,
    "pinned_by": [],
    "reply_message_id": null
  }

  const chatAdding = async () => {
    setLoader(true)
    try {
      const response = await apis.addChat(ChatBody);
      if (response?.data?.status == "success") {
        setLoader(false)
        chatUpdations()
      }
    } catch (addChatError) {
      console.error("Error adding chat box:", addChatError);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'something went wrong!!'
      });
      setLoader(false)
    }
  };

  const DocBody: any = {
    "chat_box_id": selectedRole == "Buyer" ? buyerChatBoxId : selectedRole == "Seller" ? sellerChatBoxId : "",
    "text": null,
    "doc": fileData,
    "doc_name": uploadDocument,
    "doc_size": documentSize,
    "caption": null,
    "accepted_by": null,
    "forwarded": false,
    "forwarded_from": null,
    "pinned_by": [],
    "reply_message_id": null
  }


  const docAdding = async () => {
    setLoader(true)
    try {
      const response = await apis.addChat(DocBody);
      if (response?.data?.status == "success") {
        setLoader(false)
        chatUpdations()
        setDocumentpopupOpen(false)
      }
    } catch (addChatError) {
      console.error("Error adding chat box:", addChatError);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'something went wrong!!'
      });
      setLoader(false)
    }
  };






  if (loader) {
    return (
      <div className="centered-container">
        <div className="loader"></div>
      </div>
    )
  } else {
    return (
      <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: "center", backgroundColor: 'rgba(250,250,250,1)', height: '90vh', overflow: "hidden", scrollbarWidth: 'none', overflowX: 'hidden' }}>
        <Grid xs={12} md={12} lg={12} width={"100%"} spacing={2} sx={{ backgroundColor: 'rgba(250,250,250,1)' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              backgroundColor: '#FFF',
              margin: "10px",
              padding: "15px",
              width: '98%',
              alignItems: 'center',
            }}
          >
            <Breadcrumb
              linkDetails={linkDetails}
              underline="always"
              maxItems={3}
              itemBeforeCollapse={1}
              itemAfterCollapse={1}
              iconName="arrow_forward_ios_black_24dp"
              iconSize={20}
              iconLabel="Breadcrumb-Arrow-Right"
              iconStyle="regular"
              color="#333"
              textColor="#333"
            />
          </Box>
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbar.severity as AlertColor}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
        <div
          style={{
            width: '100%',
            padding: "10px",
            paddingTop: 0,
            marginBottom: '10px',
            backgroundColor: 'rgba(250,250,250,1)',
            overflowY: "scroll"
          }}
        >

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(250,250,250,1)'
            }}
          >

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgba(250,250,250,1)',

              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '20px',
                  flexWrap: "wrap",
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  borderRadius: '8px',
                  padding: '5px',
                  backgroundColor: '#FFF'
                }}
              >
                <Typography
                  sx={{
                    color: '#333',
                    fontFamily: 'Inter',
                    fontSize: deviceType == "mobile" ? "14px" : '19px',
                    fontWeight: 700,
                    lineHeight: '22px',
                    padding: "10px 0px"
                  }}
                >
                  Proposal Details
                  <button style={{ border: '0px solid transparent', backgroundColor: "transparent" }} onClick={() => { setClickDownload(!clickDownload) }}>
                    <img
                      style={{ height: "20px" }}
                      src={clickDownload ? collapse : expand}
                      alt=""
                    />
                  </button>
                </Typography>



                <div
                  style={{
                    padding: 5, margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: deviceType === "mobile" ? "center" : 'flex-end',
                    alignItems: 'center',
                    gap: "5px",
                    flexWrap: "wrap",
                    border: '0px solid transparent',
                    backgroundColor: 'transparent'
                  }}>
                  {(isItAdmin || isBuyer) && (<>
                    <a href={proposalDetails?.requirement_doc} target='_blank' download={"proposalDetails"} style={{
                      textDecoration: "none", display: 'flex',
                      flexDirection: 'row',
                      gap: "5px",
                      justifyContent: deviceType === "mobile" ? "center" : 'flex-end',
                    }}>
                      <img src={download} style={{
                        color: 'red',
                        width: "24px",
                        height: "24px",
                        border: "2px 0px 0px 0px",
                      }} />
                      <p style={{
                        margin: 0,
                        padding: 0,
                        color: "rgba(47, 128, 237, 1)",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        textAlign: "center",
                      }}>Requirement Document</p>
                    </a>
                  </>)}
                </div>


              </div>
              {clickDownload && (<div style={{ width: '100%', margin: 0, padding: 0, paddingTop: '10px', backgroundColor: '#FFF', }}>
                {(isItAdmin || isBuyer) && (<Row style={{
                  width: '100%',
                  margin: 0, padding: 0,
                }}>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.buyer?.name}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Buyer Name'}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.buyer?.mobile}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Phone Number'}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.buyer?.email}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Email Address'}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.buyer?.organization?.name}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Organisation Name'}
                      </div>
                    </div>
                  </Col>
                </Row>)}
                <Row style={{
                  width: '100%',
                  margin: 0, padding: 0,
                }}>


                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.asset_type}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Asset Type'}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.sports[0]}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Sports'}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {"Mohammad Shami"}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Asset Name'}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'flex-start',
                  alignItems:'flex-start'
                }}>
                  <TableContainer style={{ borderStyle: 'none', boxShadow: "none", padding: 0, width: '50%' }}>
                    <Table style={{ borderStyle: 'none', padding: 0, }}>
                      <TableHead style={{ backgroundColor: 'rgba(240, 239, 239, 0.6)', padding: '10px' }}>
                        <TableRow>
                          {["Opportunities", "Scope"].map((header, index) => (
                            <TableCell
                              key={index}
                              style={{
                                fontFamily: 'Inter',
                                fontSize: deviceType == 'mobile' ? '14px' : '16px',
                                fontWeight: 700,
                                lineHeight: '21px',
                                letterSpacing: '-0.3333333432674408px',
                                textAlign: 'left',
                                borderStyle: 'none',
                                justifyContent: 'center',
                                marginLeft: 0,
                                textTransform: 'capitalize',
                                backgroundColor: 'rgba(224, 224, 224, 1)',
                                borderTopRightRadius: "3px",
                                borderTopLeftRadius: "3px",
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex', alignItems: 'center',
                                  color: '#111',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  lineHeight: 'normal',
                                }}
                              >
                                {header}

                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {proposalDetails?.opportunities?.map((item: any, index: any) => (
                          <TableRow
                            key={index}
                            style={{
                              background: index % 2 == 0 ? "#fff" : "#f9f9f9",
                              height: "30px",
                              border: "0px solid transparent",
                              fontSize: deviceType == 'mobile' ? '14px' : '16px',
                              lineHeight: "21px",
                              paddingLeft: '18px',
                            }}
                          >
                            <TableCell style={{ height: "30px", border: "0px solid transparent", fontSize: deviceType == 'mobile' ? '14px' : '16px', lineHeight: "21px" }}>
                              {item?.opportunity}
                            </TableCell>
                            <TableCell style={{ height: "30px", border: "0px solid transparent", fontSize: deviceType == 'mobile' ? '14px' : '16px', lineHeight: "21px" }}>
                              {item?.scope}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Row style={{
                  width: '100%',
                  margin: 0, padding: 0,
                }}>
                  <Col xs={12} md={12} lg={12} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {"Requirement Details"}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {proposalDetails?.requirement}
                      </div>
                    </div>
                  </Col>
                </Row>
                {(isItAdmin) && (<hr />)}
                {(isItAdmin) && (<Row style={{
                  width: '100%',
                  margin: 0, padding: 0,
                  paddingBottom: '10px'
                }}>
                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.seller?.name}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Seller Name'}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.seller?.mobile}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Phone Number'}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.seller?.email}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Email Address'}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                    <div style={{}}>
                      <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                        {proposalDetails?.seller?.organization?.name}
                      </div>
                      <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                        {'Organisation Name'}
                      </div>
                    </div>
                  </Col>
                </Row>)}
              </div>)}
            </div>




            {(!isSeller && !isSellerAdmin && !isBuyer) && (<div style={{
              width: deviceType == "mobile" ? "100%" : "auto", flexDirection: 'row', display: 'flex', justifyContent: deviceType == "mobile" ? 'flex-start' : "flex-start", scrollbarWidth: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)', gap: '10px', marginTop: '10px', marginBottom: '10px', overflowX: "scroll", overflowY: 'hidden',
              borderRadius: '8px',
              padding: '0px',
              backgroundColor: '#FFF'
            }}>
              <div style={selectedRole == "Buyer" ?
                sortingStyles.tabButton : sortingStyles.tabButtonInactive
              } onClick={() => {
                setSelectedRole('Buyer');
                scrollToBottom()
              }}>Buyer</div>
              <div style={selectedRole == "Seller" ?
                sortingStyles.tabButton : sortingStyles.tabButtonInactive
              } onClick={() => {
                setSelectedRole('Seller');
                scrollToBottom()
              }}>Seller</div>

            </div>)}




          </div>
          {loader && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '45vh',
                left: '50%',
                position: 'absolute',
              }}
            >
              <Loader status={loader} size={50} />
            </div>
          )}
          {!loader && (
            <div style={{ width: '100%', display: 'flex', flexDirection: deviceType == "mobile" ? "column" : 'row', justifyContent: deviceType == "mobile" ? "flex-start" : 'space-evenly', alignItems: 'flex-start', gap: '10px', marginTop: (isSeller || isBuyer) ? '10px' : "0px", overflowY: deviceType == "mobile" ? "scroll" : "scroll" }}>
              <div style={{ width: deviceType == "mobile" ? "100%" : '60%', display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: 'flex-start', alignSelf: "center", backgroundColor: '#FFF', borderRadius: '8px' }}>
                {(selectedRole == "Buyer") ? (
                  <>
                    {(isBuyerChatExist) ? (
                      <>
                        <div style={{
                          width: "100%",
                          height: "50px",
                          padding: "16px",
                          gap: "5px",
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          background: '#FFF',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: "space-between",
                          alignItems: 'center',
                          borderRadius: '8px',
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: '0px',
                          marginBottom: '2px'
                        }}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: deviceType == "mobile" ? "space-between" : "flex-start",
                            alignItems: 'center',
                            gap: '10px',
                            padding: '20px 0px'
                          }}>
                            <div
                              onClick={() => { }}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 100,
                                margin: 0,
                                width: '32px',
                                height: '32px',
                                cursor: 'pointer',
                                backgroundColor: '#D2EAF4',
                              }}
                            >
                              <span style={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: 500,
                                lineHeight: "28px",
                              }}>{buyerName?.charAt(0)}</span>
                            </div>
                            <p style={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.36px",
                              textAlign: "left",
                              padding: 0,
                              margin: 0
                            }}>
                              {buyerName}
                            </p>
                            {(deviceType == "mobile") && (<p style={{
                              height: 'auto',
                              padding: "4px 10px 4px 10px",
                              borderRadius: "100px",
                              backgroundColor: 'rgba(195, 248, 231, 1)',
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 700,
                              lineHeight: "21px",
                              textAlign: "left",
                              color: 'rgba(0, 132, 90, 1)',
                              margin: 0,
                            }}>
                              {steps[activeStep].label || ""}
                            </p>)}
                          </div>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "flex-start",
                            alignItems: 'center',
                            gap: deviceType == "mobile" ? "0px" : '10px'
                          }}>
                            {(deviceType !== "mobile") && (<p style={{
                              height: 'auto',
                              padding: "4px 10px 4px 10px",
                              borderRadius: "100px",
                              backgroundColor: 'rgba(195, 248, 231, 1)',
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "21px",
                              textAlign: "left",
                              color: 'rgba(0, 132, 90, 1)',
                              margin: 0,
                            }}>
                              {steps[activeStep].label || ""}
                            </p>)}
                            <button
                              onClick={() => { setSearchPresent(!isSearchPresent) }}
                              style={{
                                width: "24px",
                                height: "24px",
                                padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent', cursor: 'pointer'
                              }}>
                              <img src={Search} style={{
                                width: "24px",
                                height: "24px",
                              }} />
                            </button>
                            <>
                              <button style={{
                                width: "24px",
                                height: "24px",
                                padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                                cursor: 'pointer'
                              }}>
                                <img src={Pinned} style={{
                                  width: "24px",
                                  height: "24px",
                                }} />
                              </button>
                              <p style={{
                                fontFamily: "Inter",
                                fontSize: deviceType == "mobile" ? "13px" : "16px",
                                fontWeight: 500,
                                lineHeight: "19.36px",
                                textAlign: "left",
                                padding: 0,
                                margin: 0
                              }}>
                                2 Items Pinned
                              </p>
                            </>
                          </div>
                        </div>

                        <div style={{
                          width: "100%",
                          height: "55vh",
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          borderRadius: '8px'
                        }}>
                          {(isSearchPresent) && (<div style={{
                            width: "100%",
                            height: "44px",
                            padding: "16px",
                            gap: "5px",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            background: " rgba(242, 242, 242, 1)",
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-evenly",
                            alignItems: 'center',
                            borderRadius: '0px'
                          }}>
                            <img
                              src={SearchNormal}
                              style={{
                                width: "5%",
                                height: "20px",

                              }}
                            />
                            <input
                              type="text"
                              style={{
                                backgroundColor: 'transparent',
                                border: '0px solid transparent',
                                outline: 'none',
                                width: '60%',
                                color: 'rgba(130, 130, 130, 1)',
                              }}
                              placeholder='Find messages in current conversation'
                              value={searchValue}
                              onChange={(e) => { setSearchValue(e.target.value) }}
                            />
                            <Close
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() => { setSearchValue("") }}
                            />
                            <p style={{
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "16.94px",
                              textAlign: "left",
                              margin: 0,
                              padding: 0
                            }}>
                              1/2
                            </p>
                            <button style={{
                              width: "5%",
                              height: "24px",
                              padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                              cursor: 'pointer'
                            }}>
                              <KeyboardArrowUpOutlined style={{
                                height: "24px",
                                color: '#000'
                              }} />

                            </button>
                            <button style={{
                              width: "5%",
                              height: "24px",
                              padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                              cursor: 'pointer'
                            }}>
                              <KeyboardArrowDownOutlined style={{
                                height: "24px",
                                color: '#000'
                              }} />

                            </button>
                            <button
                              onClick={() => { setSearchPresent(!isSearchPresent) }}
                              style={{
                                width: "10%",
                                height: "24px",
                                color: "rgba(130, 130, 130, 1)",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 500,
                                padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                                cursor: 'pointer'
                              }}>
                              Cancel
                            </button>

                          </div>)}

                          <div
                            ref={divRef}
                            style={{
                              width: "100%",
                              height: "80vh",
                              padding: "16px",
                              gap: "20px",
                              backgroundColor: '#FFF',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              borderRadius: '8px',
                              zIndex: 2,
                              overflowX: 'hidden',
                              overflowY: 'scroll',
                            }}>
                            {Object.keys(buyerchats || {})?.map((day: any, index: any) => (
                              <div key={day}
                                ref={divRef}
                                style={{
                                  display: 'flex',
                                  width: "100%",
                                  flexDirection: 'column',
                                  gap: "10px",
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}>
                                <p style={{
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  lineHeight: "16.94px",
                                  fontFamily: 'Inter',
                                  margin: 0,
                                  padding: 0,
                                  textAlign: 'center',
                                  position: 'relative',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                  width: '100%'
                                }}>
                                  <span style={{ flexGrow: 1, height: '1px', backgroundColor: 'var(--Theme-colors-border-2, rgba(229, 229, 229, 1))' }}></span>
                                  {day}
                                  <span style={{ flexGrow: 1, height: '1px', backgroundColor: 'var(--Theme-colors-border-2, rgba(229, 229, 229, 1))' }}></span>
                                </p>


                                {buyerchats[day]?.map((message: any, index: any) => (
                                  <Text item={message} key={index} />
                                ))}
                              </div>
                            ))}


                          </div>

                          <div style={{
                            width: "100%",
                            height: "20%",
                            padding: "16px",
                            gap: "5px",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            background: '#FFF',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            alignItems: 'center',
                            borderRadius: '8px',
                          }}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: "space-between",
                              alignItems: 'center',
                              width: '100%',
                              height: "48px",
                              borderRadius: '18px',
                              border: "1px solid var(--Theme-colors-border-2, rgba(229, 229, 229, 1))"
                            }}>
                              <Button
                                onClick={() => {
                                  setDocumentpopupOpen(true);
                                  setFileData([]);
                                  setDocumentSize("");
                                  setUploadDocument("");
                                }}
                                sx={{
                                  width: "10%",
                                  height: "48px",
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: "center",
                                  alignItems: 'center',
                                  border: '0px solid transparent',
                                  backgroundColor: 'transparent',
                                  padding: 0,
                                  margin: 0,
                                  '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.015)'
                                  }
                                }}>
                                <img src={menubase} style={{
                                  width: "30px",
                                  height: "30px",
                                }} />
                              </Button>
                              <div style={{
                                width: "75%",
                                height: "48px"
                              }}>
                                <input
                                  type="text"
                                  style={{
                                    backgroundColor: 'transparent',
                                    border: '0px solid transparent',
                                    outline: 'none',
                                    width: '100%',
                                    height: "48px",
                                    paddingLeft: '10px',
                                    color: 'rgba(130, 130, 130, 1)',
                                    margin: 0,
                                    padding: 0
                                  }}
                                  placeholder='Type here...'
                                  value={searchValue}
                                  onChange={(e) => { setSearchValue(e.target.value) }}
                                  onKeyDown={(e: any) => {
                                    if (e.key == "Enter") {
                                      chatAdding()
                                      setSearchValue("")
                                    }
                                  }}
                                />
                              </div>
                              <div style={{
                                // width: "20%",
                                width: 'auto',
                                height: "48px",
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '20px'
                              }}>

                                <button
                                  disabled={!searchValue}
                                  onClick={() => {
                                    chatAdding()
                                    setSearchValue("")
                                  }}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    padding: 0,
                                    margin: 0,
                                    border: '0px solid transparent',
                                    backgroundColor: 'transparent'
                                  }}
                                >
                                  <img src={sendbutton} style={{
                                    width: "24px",
                                    height: "24px",
                                  }} />
                                </button>
                              </div>

                            </div>

                          </div>

                        </div>
                      </>
                    ) : (
                      <div style={{
                        width: deviceType == "mobile" ? "100%" : '100%', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", alignSelf: "center", backgroundColor: 'rgba(250,250,250,1)', borderRadius: '8px'
                      }}>
                        {(buyerloading) ? (
                          <div className="loader"></div>
                        )
                          : (<Button sx={{ border: '1px solid #e22b16', height: '40px', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: '5px' }} onClick={() => { setBuyerLoading(true); AddChatBoxApi(buyerid, buyerorgid) }}>
                            <MarkUnreadChatAltIcon sx={{ color: '#e22b16', width: '20px', height: '20px' }} /> <span style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '500' }}>Initiate Chat</span>
                          </Button>)}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {(isSellerChatExist) ? (<>
                      <div style={{
                        width: "100%",
                        height: "50px",
                        padding: "16px",
                        gap: "5px",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        borderRadius: '8px',
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: '0px',
                        marginBottom: '2px'
                      }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: deviceType == "mobile" ? "space-between" : "flex-start",
                          alignItems: 'center',
                          gap: '10px',
                          padding: '20px 0px',

                        }}>
                          <div
                            onClick={() => { }}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 100,
                              margin: 0,
                              width: '32px',
                              height: '32px',
                              cursor: 'pointer',
                              backgroundColor: '#D2EAF4',
                            }}
                          >
                            <span style={{
                              fontFamily: "Inter",
                              fontSize: "20px",
                              fontWeight: 500,
                              lineHeight: "28px",
                            }}>{sellerName.charAt(0)}</span>
                          </div>
                          <p style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "19.36px",
                            textAlign: "left",
                            padding: 0,
                            margin: 0
                          }}>

                            {sellerName}
                          </p>
                          {(deviceType == "mobile") && (<p style={{
                            height: 'auto',
                            padding: "4px 10px 4px 10px",
                            borderRadius: "100px",
                            backgroundColor: 'rgba(195, 248, 231, 1)',
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 700,
                            lineHeight: "21px",
                            textAlign: "left",
                            color: 'rgba(0, 132, 90, 1)',
                            margin: 0,
                          }}>

                            {steps[activeStep].label || ""}

                          </p>)}
                        </div>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: "flex-start",
                          alignItems: 'center',
                          gap: deviceType == "mobile" ? "0px" : '10px'
                        }}>
                          {(deviceType !== "mobile") && (<p style={{
                            height: 'auto',
                            padding: "4px 10px 4px 10px",
                            borderRadius: "100px",
                            backgroundColor: 'rgba(195, 248, 231, 1)',
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "21px",
                            textAlign: "left",
                            color: 'rgba(0, 132, 90, 1)',
                            margin: 0,
                          }}>
                            {steps[activeStep].label || ""}
                          </p>)}
                          <button
                            onClick={() => { setSearchPresent(!isSearchPresent) }}
                            style={{
                              width: "24px",
                              height: "24px",
                              padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent', cursor: 'pointer'
                            }}>
                            <img src={Search} style={{
                              width: "24px",
                              height: "24px",
                            }} />
                          </button>
                          <>
                            <button style={{
                              width: "24px",
                              height: "24px",
                              padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                              cursor: 'pointer'
                            }}>
                              <img src={Pinned} style={{
                                width: "24px",
                                height: "24px",
                              }} />
                            </button>
                            <p style={{
                              fontFamily: "Inter",
                              fontSize: deviceType == "mobile" ? "13px" : "16px",
                              fontWeight: 500,
                              lineHeight: "19.36px",
                              textAlign: "left",
                              padding: 0,
                              margin: 0
                            }}>
                              2 Items Pinned
                            </p>
                          </>
                        </div>
                      </div>

                      <div style={{
                        width: "100%",
                        height: "55vh",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: '8px'
                      }}>
                        {(isSearchPresent) && (
                          <div style={{
                            width: "100%",
                            height: "44px",
                            padding: "16px",
                            gap: "5px",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            background: " rgba(242, 242, 242, 1)",
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-evenly",
                            alignItems: 'center',
                            borderRadius: '0px'
                          }}>
                            <img
                              src={SearchNormal}
                              style={{
                                width: "5%",
                                height: "20px",

                              }}
                            />
                            <input
                              type="text"
                              style={{
                                backgroundColor: 'transparent',
                                border: '0px solid transparent',
                                outline: 'none',
                                width: '60%',
                                color: 'rgba(130, 130, 130, 1)',
                              }}
                              placeholder='Find messages in current conversation'
                              value={searchValue}
                              onChange={(e) => { setSearchValue(e.target.value) }}
                            />
                            <Close
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() => { setSearchValue("") }}
                            />
                            <p style={{
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "16.94px",
                              textAlign: "left",
                              margin: 0,
                              padding: 0
                            }}>
                              1/2
                            </p>
                            <button style={{
                              width: "5%",
                              height: "24px",
                              padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                              cursor: 'pointer'
                            }}>
                              <KeyboardArrowUpOutlined style={{
                                height: "24px",
                                color: '#000'
                              }} />

                            </button>
                            <button style={{
                              width: "5%",
                              height: "24px",
                              padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                              cursor: 'pointer'
                            }}>
                              <KeyboardArrowDownOutlined style={{
                                height: "24px",
                                color: '#000'
                              }} />

                            </button>
                            <button
                              onClick={() => { setSearchPresent(!isSearchPresent) }}
                              style={{
                                width: "10%",
                                height: "24px",
                                color: "rgba(130, 130, 130, 1)",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 500,
                                padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                                cursor: 'pointer'
                              }}>
                              Cancel
                            </button>

                          </div>
                        )}

                        <div
                          ref={divRef}
                          style={{
                            width: "100%",
                            height: "80vh",
                            padding: "16px",
                            gap: "20px",
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            borderRadius: '8px',
                            zIndex: 2,
                            overflowX: 'hidden',
                            overflowY: 'scroll',
                          }}>

                          {Object.keys(sellerchats || {})?.map((day: any, index: any) => (
                            <div key={day}
                              ref={divRef}
                              style={{
                                display: 'flex',
                                width: "100%",
                                flexDirection: 'column',
                                gap: "10px",
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                              }}>
                              <p style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: "16.94px",
                                fontFamily: 'Inter',
                                margin: 0,
                                padding: 0,
                                textAlign: 'center',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                width: '100%'
                              }}>
                                <span style={{ flexGrow: 1, height: '1px', backgroundColor: 'var(--Theme-colors-border-2, rgba(229, 229, 229, 1))' }}></span>
                                {day}
                                <span style={{ flexGrow: 1, height: '1px', backgroundColor: 'var(--Theme-colors-border-2, rgba(229, 229, 229, 1))' }}></span>
                              </p>


                              {sellerchats[day]?.map((message: any, index: any) => (
                                <Text item={message} key={index} />
                              ))}
                            </div>
                          ))}

                        </div>

                        <div style={{
                          width: "100%",
                          height: "20%",
                          padding: "16px",
                          gap: "5px",
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          background: '#FFF',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: "space-between",
                          alignItems: 'center',
                          borderRadius: '8px',
                        }}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            alignItems: 'center',
                            width: '100%',
                            height: "48px",
                            borderRadius: '18px',
                            border: "1px solid var(--Theme-colors-border-2, rgba(229, 229, 229, 1))"
                          }}>
                            <Button
                              onClick={() => {
                                setDocumentpopupOpen(true);
                                setFileData([]);
                                setDocumentSize("");
                                setUploadDocument("");
                              }}
                              sx={{
                                width: "10%",
                                height: "48px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "none",
                                backgroundColor: "transparent",
                                padding: 0,
                                margin: 0,
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.015)",
                                },
                              }}
                            >
                              <img
                                src={menubase}
                                alt="menu icon"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            </Button>

                            <div style={{
                              width: "75%",
                              height: "48px"
                            }}>
                              <input
                                type="text"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: '0px solid transparent',
                                  outline: 'none',
                                  width: '100%',
                                  height: "48px",
                                  paddingLeft: '10px',
                                  color: 'rgba(130, 130, 130, 1)',
                                  margin: 0,
                                  padding: 0
                                }}
                                placeholder='Type here...'
                                value={searchValue}
                                onChange={(e) => { setSearchValue(e.target.value) }}
                                onKeyDown={(e: any) => {
                                  if (e.key == "Enter") {

                                    chatAdding()
                                    setSearchValue("")
                                  }
                                }}
                              />
                            </div>
                            <div style={{
                              // width: "20%",
                              width: 'auto',
                              height: "48px",
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: '20px'
                            }}>

                              <button
                                disabled={!searchValue}
                                onClick={() => {

                                  chatAdding()
                                  setSearchValue("")
                                }}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  padding: 0,
                                  margin: 0,
                                  border: '0px solid transparent',
                                  backgroundColor: 'transparent'
                                }}
                              >
                                <img src={sendbutton} style={{
                                  width: "24px",
                                  height: "24px",
                                }} />
                              </button>
                            </div>

                          </div>

                        </div>

                      </div>
                    </>) : (
                      <div style={{
                        width: deviceType == "mobile" ? "100%" : '100%', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", alignSelf: "center", backgroundColor: 'rgba(250,250,250,1)', borderRadius: '8px'
                      }}>
                        {
                          (sellerloading) ? (
                            <div className="loader"></div>
                          )
                            : (
                              <Button sx={{ border: '1px solid #e22b16', height: '40px', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: '5px' }} onClick={() => { setSellerLoading(true); AddChatBoxApi(sellerid, sellerorgid) }}>
                                <MarkUnreadChatAltIcon sx={{ color: '#e22b16', width: '20px', height: '20px' }} /> <span style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '500' }}>Initiate Chat</span>
                              </Button>
                            )}
                      </div>
                    )}
                  </>
                )}

              </div>
              <div style={{ width: deviceType == "mobile" ? "100%" : '40%', display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: 'flex-start', backgroundColor: '#FFF', borderRadius: '8px', borderBottom: "1px solid rgba(224, 224, 224, 1)", }}>
                <div style={{
                  width: "100%",
                  height: "50px",
                  padding: "16px",
                  border: "0px 0px 1px 0px",
                  gap: "5px",
                  background: '#FFF',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: "space-between",
                  alignItems: 'center',
                  borderRadius: '8px',
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: '0px',
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",

                }}>
                  <p style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "19.36px",
                    textAlign: "left",
                    padding: 0,
                    margin: 0
                  }}>
                    Proposal Status
                  </p>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "flex-start",
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                    <div style={{
                      height: "29px",
                      padding: "4px 10px 4px 10px",
                      gap: "10px",
                      borderRadius: "100px",
                      backgroundColor: 'rgba(195, 248, 231, 1)',
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "21px",
                      textAlign: "left",
                      color: 'rgba(0, 132, 90, 1)',
                      textTransform: 'capitalize'
                    }}>
                      {proposalDetails?.proposal_status}
                    </div>
                    <button
                      onClick={handleClick}
                      style={{
                        width: "24px",
                        height: "24px",
                        padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
                        cursor: 'pointer'
                      }}>
                      <img src={Edit} style={{
                        width: "24px",
                        height: "24px",
                      }} />
                    </button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      sx={{
                        boxShadow: "4px 4px 12px 0px rgba(0, 0, 0, 0.07)",
                        borderRadius: '10px',

                      }}
                    >
                      <MenuList
                        sx={{
                          outline: 'none',
                          borderRadius: '10px',
                          marginTop: 0,
                          marginBottom: 0,
                          padding: 0,
                          '& .MuiList-root MuiList-padding css-18bys5e-MuiList-root': {
                            marginTop: 0,
                            marginBottom: 0
                          },
                          '& .MuiList-root.MuiList-padding': {
                            marginTop: 0,
                            marginBottom: 0
                          },
                          '& .css-6hp17o-MuiList-root-MuiMenu-list': {
                            paddingTop: 0,
                            paddingBottom: 0,
                          }
                        }}
                      >
                        {
                          [
                            {
                              key: 1,
                              name: "Terminate Deal",
                              disabled: false,
                            },
                            {
                              key: 2,
                              name: "Mark Lead as Cold",
                              disabled: false,
                            },
                            {
                              key: 3,
                              name: "Mark Lead as Warm",
                              disabled: false,
                            },
                            {
                              key: 4,
                              name: "Closed",
                              disabled: false,
                            }
                          ].map((menudata) => !menudata.disabled && (
                            <MenuItem
                              key={menudata.key}
                              onClick={() => {
                                if (menudata.key == 1) {
                                  setpopupOpen(true)
                                }
                              }}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: 0,
                                '&:hover': {
                                  background: 'rgba(0,0,0,0.01)',
                                },
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >

                                <ListItemText
                                  sx={{
                                    fontSize: '10px',
                                    fontWeight: 500,
                                    color: '#333',
                                    fontFamily: "Inter",
                                    lineHeight: "21px",
                                    textAlign: "left",
                                  }}
                                >
                                  {menudata.name}
                                </ListItemText>
                              </div>
                            </MenuItem>
                          ))
                        }
                      </MenuList>
                    </Menu>
                  </div>

                </div>
                <div style={{
                  width: "100%",
                  height: "55vh",

                }}>
                  <Box sx={{ width: '100%', height: "54vh", overflowY: 'scroll', padding: '10px' }}>
                    <Stepper orientation="vertical" activeStep={activeStep}>
                      {steps.map((item, index) => (
                        <Step key={index} completed={completed[index]} sx={{

                          padding: 0, margin: 0,
                          '.MuiStepConnector-line': {
                            maxHeight: '5px',
                          },
                          '& .MuiStepConnector-line, & .MuiStepConnector-lineVertical, & .css-8t49rw-MuiStepConnector-line': {
                            borderStyle: 'dashed',
                            borderColor: '#000',
                          },
                          '& .css-8t49rw-MuiStepConnector-line': {
                            borderStyle: 'dashed',
                            borderColor: '#000',

                          },
                          '& .MuiStepConnector-root, & .MuiStepConnector-root': {
                            maxHeight: '5px',
                            padding: 0, margin: 0
                          }
                        }}>
                          <StepButton color="inherit" onClick={() => { handleStep(index) }}
                            sx={{
                              padding: 0,
                              margin: 0,
                              display: "block",
                              '& .css-14sza3e-MuiStepLabel-root': {
                                padding: 0, margin: 0
                              },
                              '& .Mui-active': {
                                color: 'rgba(39, 174, 96, 1)'
                              },
                              '& .Mui-active, & .Mui-completed': {
                                color: 'rgba(39, 174, 96, 1)'
                              },
                              '& .css-qeoe7e-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
                                color: 'rgba(39, 174, 96, 1)'
                              },
                              '& .css-qeoe7e-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                                color: 'rgba(39, 174, 96, 1)'
                              }

                            }}>
                            <div style={{ width: '100%', padding: 0, margin: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div style={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: "flex-start", color: "rgba(51, 51, 51, 1)", }}>
                                <p style={{
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  lineHeight: "16px",
                                  textAlign: "left",
                                  padding: 0,
                                  margin: 0
                                }}>{item?.label}</p>

                              </div>
                              <div style={{
                                padding: 0,
                                margin: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                gap: '10px'
                              }}>

                                {(index < activeStep) ? (<>
                                  <img src={TickCircleGreen} style={{ width: '24px', height: '24px', color: "green" }} />
                                </>) :

                                  (
                                    (isItAdmin) ? (<button
                                      onClick={() => { AddProposalLeadStatus(item.label); }}
                                      style={{
                                        width: "110px",
                                        height: "25px",
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: "5px",
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: 'rgba(130, 130, 130, 1)',
                                        backgroundColor: 'transparent',
                                        border: "1px solid rgba(189, 189, 189, 1)"

                                      }}>
                                      {item?.buttonName}
                                    </button>) : (<></>)
                                  )
                                }
                              </div>
                            </div>
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>

                  </Box>
                </div>
              </div>
            </div>
          )}

          <Modal
            open={popupopen}
            onClose={handleClosepopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, borderRadius: 3, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', }}>
              <Box sx={{
                width: "100%",
                height: "auto",
                display: 'flex',
                flexDirection: "row",
                padding: "10px 16px 10px 16px",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                justifyContent: "space-between",
              }} >
                <p style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "21px",
                  textAlign: "left",
                  padding: 0,
                  margin: 0,
                  color: "rgba(51, 51, 51, 1)"
                }}>
                  Terminate Deal
                </p>

                <Close
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => { setpopupOpen(false) }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '10px' }}>
                <Box sx={{
                  width: "100%",
                  display: 'flex',
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: '10px',
                  padding: "8px 16px 8px 16px",
                  marginTop: '10px'
                }} >
                  <ZupotsuTextfield
                    title="Current Status"
                    placeholder={"Terminate"}
                    value={""}
                    type={"text"}
                    name={"currentstatus"}
                    multiline={false}
                    handleChange={(event: any) => {

                    }}
                  />

                  <ZupotsuDropdown
                    title="Expiry Date"
                    placeholder={"Select Expiry Date"}
                    value={""}
                    name={"expirydate"}
                    dropdownData={[]}
                    handleChange={(event: any) => {

                    }}
                  />
                </Box>

                <Box sx={{
                  width: "100%",
                  display: 'flex',
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: '10px',
                  padding: "8px 16px 8px 16px",
                  marginTop: '0px'
                }} >
                  <ZupotsuTextfield
                    title="Terminate Remark"
                    placeholder={"Enter Reason"}
                    value={""}
                    type={"text"}
                    name={"terminateremark"}
                    multiline={true}
                    rows={4}
                    handleChange={(event: any) => {

                    }}
                  />
                </Box>

                <Box sx={{
                  width: "100%",
                  display: 'flex',
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  gap: '10px',
                  padding: "8px 16px 8px 16px",
                  marginTop: '0px'
                }} >

                  <Typography
                    style={{
                      marginBottom: '0px',
                      color: 'var(--Gray-1, #333)',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      lineHeight: '140%',
                      display: 'flex',
                      flexDirection: 'column',
                      fontWeight: '600'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          fontStyle: 'Inter',
                          fontWeight: '600',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            lineHeight: "21px",
                            fontStyle: 'Inter',
                            fontWeight: '700',
                          }}
                        >{"Upload Document"}</span>


                      </div>

                    </div>

                  </Typography>

                  <TextField
                    rows={1}
                    size="small"
                    placeholder={"Browse"}
                    fullWidth
                    value={uploadDocument}
                    disabled={false}
                    name={'uploaddocument'}
                    id="fullWidth"
                    onClick={handleBrowseClick}
                    sx={{
                      color: "#000",
                      background: 'transparent',
                      cursor: 'pointer',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={documentupload}
                            alt=""
                            style={{
                              width: '24px',
                              height: '24px',
                            }}
                          />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />

                </Box>
              </Box>

              <Box sx={{
                width: "100%",
                height: "auto",
                display: 'flex',
                flexDirection: "row",
                alignItems: 'center',
                padding: "8px 16px 8px 16px",
                borderTop: "0px solid rgba(224, 224, 224, 1)",
                justifyContent: "center",
                marginTop: '0px',
                boxShadow: " 0px 0px 14px 0px rgba(0, 0, 0, 0.07)"
              }} >
                <ZupotsuButton
                  name={"Submit"}
                  variant={'contained'}
                  padding={"10px 40px"}
                  isCustomColors={true}
                  customOutlineColor="1px solid transparent"
                  customOutlineColorOnHover="1px solid transparent"
                  customBgColorOnhover="#E20B18"
                  customBgColor={"#E20B18"}
                  customTextColorOnHover="#FFF"
                  customTextColor="#FFF"
                  handleClick={
                    () => { }
                  }
                />
              </Box>
            </Box>
          </Modal>

          <Modal
            open={documentpopupOpen}
            onClose={() => { setDocumentpopupOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "40%",
              bgcolor: 'background.paper',
              border: '0px solid #000',
              boxShadow: 8,
              borderRadius: 5,
              p: 0,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'
            }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                width: '100%',
                height: '100%',
                padding: '10px'
              }} >
                <Box sx={{
                  width: "100%",
                  height: "auto",
                  display: 'flex',
                  flexDirection: "row",
                  padding: "8px 16px 8px 16px",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  justifyContent: "space-between",
                }} >
                  <p style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    textAlign: "left",
                    padding: 0,
                    margin: 0,
                    color: "rgba(51, 51, 51, 1)"
                  }}>
                    Select
                  </p>

                  <Close
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => { setDocumentpopupOpen(false) }}
                  />
                </Box>

                <Box sx={{
                  width: "100%",
                  display: 'flex',
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  padding: "8px",

                }} >

                  <Typography
                    style={{
                      marginBottom: '0px',
                      color: 'var(--Gray-1, #333)',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      lineHeight: '140%',
                      display: 'flex',
                      flexDirection: 'column',
                      fontWeight: '600'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          fontStyle: 'Inter',
                          fontWeight: '600',
                          marginBottom: '10px'
                        }}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            lineHeight: "21px",
                            fontStyle: 'Inter',
                            fontWeight: '700',
                          }}
                        >{"Upload Document"}</span>


                      </div>

                    </div>

                  </Typography>

                  <TextField
                    rows={1}
                    size="small"
                    placeholder={"Browse"}
                    fullWidth
                    value={uploadDocument}
                    disabled={false}
                    name={'uploaddocument'}
                    id="fullWidth"
                    onClick={handleBrowseClick}
                    sx={{
                      color: "#000",
                      background: 'transparent',
                      cursor: 'pointer',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={documentUpload}
                            alt=""
                            style={{
                              width: '24px',
                              height: '24px',
                            }}
                          />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />

                </Box>
                <div style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center', gap: '10px', marginTop: '5px' }}>
                  <hr style={{ margin: 0, padding: 0, flexGrow: 1, width: '30px' }} />
                  <span style={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}>or</span>
                  <hr style={{ margin: 0, padding: 0, flexGrow: 1, width: '30px' }} />
                </div>

                <Box sx={{
                  width: "100%",
                  display: 'flex',
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "8px",
                  marginTop: '5px'
                }} >
                  <ZupotsuDropdown
                    title="Select Form"
                    placeholder={"Select form"}
                    value={""}
                    dropdownData={[]}
                    name={"form"}
                    handleChange={(event: any) => {
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{
                width: "100%",
                height: "auto",
                display: 'flex',
                flexDirection: "row",
                alignItems: 'center',
                padding: "8px 16px 8px 16px",
                borderTop: "1px solid rgba(224, 224, 224, 1)",
                justifyContent: "center",
                marginTop: '0px',
                boxShadow: " 0px 0px 14px 0px rgba(0, 0, 0, 0.07)"
              }} >
                <ZupotsuButton
                  name={"Submit"}
                  variant={'contained'}
                  padding={"10px 40px"}
                  isCustomColors={true}
                  customOutlineColor="1px solid transparent"
                  customOutlineColorOnHover="1px solid transparent"
                  customBgColorOnhover="#E20B18"
                  customBgColor={"#E20B18"}
                  customTextColorOnHover="#FFF"
                  customTextColor="#FFF"
                  handleClick={
                    () => {

                      docAdding()

                    }
                  }
                />
              </Box>
            </Box>
          </Modal>


        </div>

      </div >
    )
  }
}

export default Allleadproposaldetails


















// <div style={{ width: deviceType == "mobile" ? "100%" : '60%', display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: 'flex-start', backgroundColor: '#FFF', borderRadius: '8px' }}>
//   <div style={{
//     width: "100%",
//     height: "64px",
//     padding: "16px",
//     border: "0px 0px 1px 0px",
//     gap: "5px",
//     background: '#FFF',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: "space-between",
//     alignItems: 'center',
//     borderRadius: '8px',
//     borderBottom: "1px solid rgba(224, 224, 224, 1)",
//   }}>
//     <p style={{
//       fontFamily: "Inter",
//       fontSize: "16px",
//       fontWeight: 500,
//       lineHeight: "19.36px",
//       textAlign: "left",
//       padding: 0,
//       margin: 0
//     }}>Seller Lists</p>

//     <div style={{
//       display: 'flex',
//       flexDirection: 'row',
//       justifyContent: "flex-start",
//       alignItems: 'center',
//       gap: '10px'
//     }}>
//       <button
//         onClick={() => { setSearchSeller(!isSearchSeller) }}
//         style={{
//           width: "24px",
//           height: "24px",
//           padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent', cursor: 'pointer'
//         }}>
//         <img src={Search} style={{
//           width: "24px",
//           height: "24px",
//         }} />
//       </button>
//       <button
//         onClick={() => { }}
//         style={{
//           width: "24px",
//           height: "24px",
//           padding: 0, margin: 0, backgroundColor: 'transparent', border: '0px solid transparent',
//           cursor: 'pointer'
//         }}>
//         <img src={addSquare} style={{
//           width: "24px",
//           height: "24px",
//         }} />
//       </button>

//     </div>

//   </div>
// </div>

{/* <button
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: 0,
                          margin: 0,
                          border: '0px solid transparent',
                          backgroundColor: 'transparent'
                        }}
                      >
                        <img src={smile} style={{
                          width: "24px",
                          height: "24px",
                        }} />
                      </button> */}
{/* <button
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: 0,
                          margin: 0,
                          border: '0px solid transparent',
                          backgroundColor: 'transparent'
                        }}
                      >
                        <img src={documentupload} style={{
                          width: "24px",
                          height: "24px",
                        }} />
                      </button> */}