import axios from 'axios';
import popupFun from '../Container/MainPage' 

const axiosInstance = axios.create({
     baseUrl :process.env.REACT_APP_ZUPOTSU_API_BASE_URL
});

axiosInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status } = error.response;
        if(status === 401&&(localStorage.getItem("userID") || localStorage.getItem("accessToken")))
        {
            window.location.href = "/loginregister"; 
            return;
        }
        else
        {
         if (status === 401) {
            alert("Session Timeout!!")
            localStorage.clear()
            window.location.href = "/loginregister"; 
            return;
        }
        return Promise.reject(error);
        }
    }
);

export default axiosInstance;