import React, { useState } from 'react'
import { Box, Button, Grid, InputAdornment, MenuItem, Paper, Select, Switch, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Table, FormControl, InputLabel } from '@mui/material';
import { DeleteIcon, EditIconn, NoDataImage, SearchNormal,rearrange } from '../../assets';
import { KeyboardArrowDownOutlined, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUpOutlined } from '@mui/icons-material';



const ReorderableReusableTable = ({ tableData, setTableData, headers, columns, handleChange }) => {

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredRow, setHoveredRow] = useState(null);


    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        const sortedData = [...tableData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setTableData(sortedData);
    };
    const handleMouseLeave = () => { }
    const headStyles = {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
        letterSpacing: '-0.3333333432674408px',
        textAlign: 'left',
        borderStyle: 'none',
        justifyContent: 'center',
        marginLeft: 0,
        paddingLeft: '18px',
        textTransform: 'capitalize',
        backgroundColor: 'rgba(224, 224, 224, 1)',
    };

   

    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const [paginatedData,setPaginatedData] = useState(tableData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });;
    const initialDnDState = {
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
        originalOrder: [],
        updatedOrder: []
    };
    
    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

    const onDragStart = (event) => {
        const initialPosition = Number(event.currentTarget.dataset.position);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: paginatedData
        });

        // Required for Firefox to enable dragging
        event.dataTransfer.setData("text/html", '');
    };

    const onDragOver = (event) => {
        event.preventDefault();

        let newList = dragAndDrop.originalOrder;
        const draggedFrom = dragAndDrop.draggedFrom;
        const draggedTo = Number(event.currentTarget.dataset.position);

        if (draggedTo === draggedFrom) return; // If dragging over itself, do nothing

        const itemDragged = newList[draggedFrom];
        const remainingItems = newList.filter((item, index) => index !== draggedFrom);

        newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ];

        setDragAndDrop({
            ...dragAndDrop,
            updatedOrder: newList,
            draggedTo: draggedTo
        });
    };

    const onDrop = (event) => {
        setPaginatedData(dragAndDrop.updatedOrder);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false
        });
    };

    const onDragLeave = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null
        });
    };

    return (
        <>
            <TableContainer style={{ borderStyle: 'none', boxShadow: "none" }}>
                <Table style={{ borderStyle: 'none' }}>

                    <TableHead style={{ backgroundColor: 'rgba(240, 239, 239, 0.6)', padding: '10px', paddingTop: '5px', paddingBottom: '5px' }}>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell
                                    key={index}
                                    style={{
                                        ...headStyles,
                                        letterSpacing: '-0.3333333432674408px',
                                        textAlign: 'left',
                                        borderStyle: 'none',
                                        justifyContent: 'center',
                                        marginLeft: 0,
                                        paddingLeft: '18px',
                                        textTransform: 'capitalize',
                                        borderTopRightRadius: index === headers.length - 1 ? "3px" : "0px",
                                        borderTopLeftRadius: index === 0 ? "3px" : "0px",
                                        backgroundColor: '#EFEFEF',
                                        cursor: index < 4 ? 'pointer' : 'default',
                                        color: '#111',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: 'normal',
                                        borderRight: index !== headers.length - 1 ? '1px solid #E0E0E0' : 'none',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        maxWidth:'200px'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex', alignItems: 'center', cursor: index < 4 ? 'pointer' : 'default',
                                            backgroundColor: '#EFEFEF',
                                            color: '#111',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        {header}
                                        
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>



                    <TableBody>
                        {paginatedData.map((item, index) => (
                            <TableRow
                                key={index}
                                data-position={index}
                                // draggable
                                // onDragStart={onDragStart}
                                // onDragOver={onDragOver}
                                // onDrop={onDrop}
                                // onDragLeave={onDragLeave}
                                // className={dragAndDrop.draggedFrom === index ? "dragging" : dragAndDrop.draggedTo === index ? "dropArea" : dragAndDrop.dragging ? "dragging" : ""}
                                // onMouseEnter={() => setHoveredRow(index)}
                                // onMouseLeave={handleMouseLeave}
                                style={{
                                    background: hoveredRow === index ? '#F7F9FF' : index % 2 === 1 ? "rgba(249, 248, 248, 1)" : 'transparent',
                                    height: "30px",
                                    border: "0px solid transparent",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    fontFamily: 'Inter',
                                    fontWeight: "500"
                                }}
                            >


                                {columns.map((col, colIndex) => (
                                    <TableCell key={colIndex} style={{ ...col.cellStyle, border: "0px solid transparent", }}>
                                        {col.render ? col.render(item[col.field], item, handleChange) : item[col.field]}
                                    </TableCell>
                                ))}

                            </TableRow>
                        ))}


                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}

export default ReorderableReusableTable
